.WorkerApplicationMainContainer {
    width: 480px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px 5px;
    background-color: rgb(78, 78, 78);
    border-radius: 15px;
    padding: 10px;
    border: 1px solid rgb(110, 110, 110);
}

.TitleAndCityContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
}

.DateAndStatusContainers {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.WorkerApplicationsHeaders {
    font-size: 20px;
    font-weight: 400;
    color: rgb(172, 172, 172);
}

.WorkerApplicationsText {
    font-size: 14px;
    font-weight: 300;
    color: rgb(172, 172, 172);
}

@media screen and (max-width: 500px) {
	/*MOBILE SECTION*/
    .WorkerApplicationMainContainer {
        width: 325px;
    }

}

