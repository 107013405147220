#PaymentInfoMainDiv {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background-color: var(--MainBackGroundColor);
	font-family: var(--MainFontType);
	color: var(--MainFontColor);
	width: 100vw;
	height: 100vh;
}

#PaymentInfoLogo {
	font-family: var(--SecondFontType);
	font-size: 50px;
	text-shadow: 2px 2px 5px rgba(44, 44, 44, 0.952);
}

.PaymentInfoHeaders {
	width: 90%;
	font-weight: 100;
	font-size: 22px;
	text-align: center;
}

#PaymentInfoBackButton {
	padding: 3px 15px;
	border: 1px solid var(--MainFontColor);
	border-radius: 35px;
	background-color: var(--MainButtonColor);
	cursor: pointer;
	font-weight: 100;
}

.PaymentInfoBtnText {
	font-weight: 300;
	margin: 0px;
}

.PaymentInfoIconsContainer {
	width: 80vw;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	margin: 20px;
}

.paymentInfoIcons {
	width: 45px;
	height: 45px;
	border-radius: 15px;
}

@media screen and (max-width: 500px) {
	/*MOBILE SECTION*/
	#PaymentInfoMainDiv {
		font-size: 12px;
	}
}
