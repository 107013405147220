#TopBar3MainDiv {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	min-width: 200px;
	height: auto;
	background-color: var(--MainBackGroundColor);
	margin: 0px;
	padding: 6px;
}

.SubFilters {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: auto;
	justify-content: center;
	margin: 0px;
}

.subFiltersContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: auto;
	margin: 0px;
}

.subFilterTitle {
	position: relative;
	left: -330px;
	top: 5px;
	font-size: 15px;
	margin: 0px;
}

#FoundedOffers {
	margin: 0px;
	color: var(--SecondFOntColor);
}

.SubFilterIconFilled {
	width: auto;
	height: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	border: 1px var(--MainFontColor) solid;
	color: var(--MainFontColor);
	border-radius: 6px;
	font-family: var(--MainFontType);
	margin: 10px;
	padding: 2px 11px;
	background-color: rgb(102, 102, 102);
	user-select: none;
	font-weight: 300;
	box-shadow: 2px 2px 5px rgba(61, 61, 61, 0.829); 
}

.subFilterTitle {
	font-family: var(--MainFontType);
	font-weight: 300;
	color: var(--SecondFOntColor);
}

.SubFilterIcon {
	width: auto;
	height: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	border: 1px solid rgb(92, 92, 92);
	color: var(--MainFontColor);
	border-radius: 6px;
	font-family: var(--MainFontType);
	margin: 8px 5px;
	padding: 2px 11px;
	user-select: none;
	font-weight: 300;
	box-shadow: 2px 2px 5px rgba(61, 61, 61, 0.829); 
}

.SubFilterIcon:hover {
	cursor: pointer;
	background-color: rgb(77, 77, 77);
}

.SubFilterIconFilled:hover {
	cursor: pointer;
}

@media screen and (max-width: 500px) {
	/*MOBILE SECTION*/

	#TopBar3MainDiv {
		width: 100%;
		height: auto;
		justify-content: flex-start;
		padding: 0px;
		margin: 0px;
	}

	.SubFilters {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100vw;
		justify-content: center;
	}

	.SubFilterIcon {
		margin: 0px 1px;
		font-size: 11px;
		padding: 2px 8px;
		border-radius: 6px;
		white-space: nowrap;
	}

	.SubFilterIconFilled {
		margin: 0px 1px;
		font-size: 11px;
		padding: 2px 9px;
		border-radius: 6px;
	}

	#FoundedOffers {
		margin: 5px;
		font-size: 15px;
		color: var(--SecondFOntColor);
	}

	.subFilterTitle {
		position: relative;
		top: -4px;
		left: 5px;
		font-size: 15px;
		margin: 0px;
		width: 100vw;
	} 

	.subFiltersContainer {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: flex-start;
		width: 100vw;
		overflow-x: scroll;
		height: auto;
		padding-left: 5px;
	}

	.subFiltersContainer::-webkit-scrollbar {
		visibility: hidden;
		width: 0px;
		height: 0px;
	}
}
