:root {
	--MainFontColor: rgb(247, 224, 181);
	--SecondFOntColor: rgb(133, 133, 133);
	--MainBackGroundColor: rgb(70, 70, 70);
	--MainFontType: "Roboto", sans-serif;
	--SecondFontType: "Anton", sans-serif;
	--MainButtonColor: rgb(197, 103, 103);
	--SecondButtonColor: rgb(151, 150, 150);
	--MainFilledFilterColor: rgb(61, 61, 61);
	margin: 0;
	padding: 0;
	background-color: var(--MainBackGroundColor);
}

html {
	margin: 0;
	padding: 0;
	min-height: 750px;
	height: auto;
	background-color: var(--MainBackGroundColor);
	background-image: url(https://firebasestorage.googleapis.com/v0/b/chat-app-9fa6b.appspot.com/o/SiteLogos%2FAnimatedWrench.gif?alt=media&token=02269676-c8f9-48cc-8ddf-3ef4baaeecf3);
	background-repeat: no-repeat;
	background-position: center;
}


html::-webkit-scrollbar {
	background-color: var(--MainBackGroundColor);
	width: 0px;
	height: 0px;
}

html::-webkit-scrollbar-thumb {
	border: 1px solid rgb(189, 189, 189);
	border-radius: 20px;
	background-color: var(--SecondFOntColor);
	width: 0px;
	height: 0px;
}

body {
	margin: 0;
	padding: 0;
	background-color: var(--MainBackGroundColor);
	height: auto;
}

body::-webkit-scrollbar {
	background-color: var(--MainBackGroundColor);
	width: 0px;
	height: 0px;
}

body::-webkit-scrollbar-thumb {
	border: 1px solid rgb(189, 189, 189);
	border-radius: 20px;
	background-color: var(--SecondFOntColor);
	width: 0px;
	height: 0px;
}

#App {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	min-width: 1100px;
	min-height: 700px;
	height: auto;
}

@media screen and (max-width: 500px) {
	/*MOBILE SECTION*/

	html {
		background-size: 70%;
		min-height: 470px;
	}

	#App {
		align-items: center;
		width: 100vw;
		min-width: 100vw;
		max-width: 100vw;
		height: auto;
		margin: 0px;
		padding: 0px;
		overflow: hidden;
	}


	:root::-webkit-scrollbar {
		width: 0px;
		display: none;
		background-color: var(--MainBackGroundColor);
	}

	:root::-webkit-scrollbar-thumb {
		display: none;
		border: 1px solid rgb(189, 189, 189);
		border-radius: 20px;
		background-color: var(--SecondFOntColor);
	}

}

@media screen and (min-width: 1900px) {
	/*BIG SCREEN SECTION*/

	#App {
		min-height: 800px;
	}
}
