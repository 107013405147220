#AddOfferChooseDiv {
	position: relative;
	top: 30px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 850px;
	font-weight: 100;
}

.OffersTypeSectionHeader {
	font-weight: 100;
}

@media screen and (max-width: 450px) {
	/*MOBILE SECTION*/

	.OffersTypeSectionHeader {
		font-weight: 100;
		font-size: 26px;
		margin: 30px 0px;
	}

	#AddOfferChooseDiv {
		padding-bottom: 25px;
	}

	.ChoosingOptionInfo {
		text-align: center;
	}
	
}