#OfferDetailsMainDiv {
	background-color: var(--MainBackGroundColor);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	height: auto;
}

.OfferDetailsBadUrlHeaders {
	font-family: var(--MainFontType);
	font-weight: 100;
}

.OfferDetailsBackLogo {
 font-size: 40px;
 font-weight: 300;
 font-family: var(--SecondFontType);
 cursor: pointer;
}

@media screen and (max-width: 500px) {
	/*MOBILE SECTION*/
	#OfferDetailsMainDiv {
		min-width: 300px;
		width: 100vw;
		height: auto;
	}

	.OfferDetailsBadUrlHeaders {
		font-family: var(--MainFontType);
		font-weight: 100;
		word-break: break-all;
	}
}
