#PoliticsPageMainDiv {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-family: var(--MainFontType);
	font-weight: 300;
	color: var(--MainFontColor);
	padding: 0px;
	width: 100%;
	height: auto;
	background-color: var(--MainBackGroundColor);
	word-wrap: break-word;
}

#TextDiv {
	width: 80%;
	height: auto;
	margin-bottom: 20px;
}

ul > li > a {
	color: var(--MainFontColor);
}

#CookiesBackToMainLink {
	font-size: 35px;
	margin: 0px;
	text-decoration: none;
	font-family: var(--SecondFontType);
	color: var(--MainFontColor);
	height: 30px;
	align-self: center;
	margin: 50px 0px;
}

@media screen and (max-width: 500px) {
	/*MOBILE SECTION*/
	#PoliticsPageMainDiv {
		position: relative;
		top: 30px;
	}
}
