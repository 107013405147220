.AvailableWorkerContainer {
    max-height: 650px;
    overflow-y: scroll;
}

.AvailableWorkerContainer::-webkit-scrollbar {
	background-color: transparent;
    width: 7px;
}

.AvailableWorkerContainer::-webkit-scrollbar-thumb {
	border: 1px solid rgb(189, 189, 189);
	border-radius: 20px;
	background-color: var(--SecondFOntColor);
}

.RolesFilterButtonsMainContainer {
    width: 600px;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    overflow-x: scroll;
}

.RolesFilterButtonsMainContainer::-webkit-scrollbar {
	background-color: transparent;
    height: 10px;
}

.RolesFilterButtonsMainContainer::-webkit-scrollbar-thumb {
	border: 1px solid rgb(189, 189, 189);
	border-radius: 20px;
	background-color: var(--SecondFOntColor);
}

.NoWorkersInfoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
  background-color: rgb(110, 110, 110);
  padding: 15px;
  border-radius: 15px;
}

.NoWorkersInfo {
    font-weight: 100;
}


@media screen and (max-width: 500px) {
	/*MOBILE SECTION*/
    .AvailableWorkerContainer {
       height: 420px;
    }

    .AvailableWorkerContainer::-webkit-scrollbar {
       width: 2px;
    }

    .AvailableWorkerContainer::-webkit-scrollbar-thumb {
    width: 2px;
    }

    .RolesFilterButtonsMainContainer {
        width: 300px;
    }
    
}