#WorkerAplicationConfirmationMainContainer {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
}

.AplicationCofirmationParagrafs {
    font-weight: 100;
    margin: 8px 0px;
    text-align: center;
}

.AplyButtons {
  height: 25px;
  width: 75px;
  margin: 5px;
  cursor: pointer;
  border-radius: 13px;
  border: none;
  background-color: rgb(112, 112, 112);
  color: var(--MainFontColor);
  font-size: 15px;
  box-shadow: 2px 2px 5px rgba(61, 61, 61, 0.829);
}

.AplicationCofirmationJobDetails {
  padding: 2px 20px;
  /* background-color: rgb(112, 112, 112); */
  border: 1px solid rgb(112, 112, 112);
  border-radius: 12px;
  margin: 15px 0px;
  user-select: none;
}

.AplyButtons:hover {
    scale: 1.05;
  }

