#ShowOnlineStatusMainContainer {
    position: fixed;
    top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 250px;
    height: 30px;
    padding: 0px 15px;
    border-radius: 20px;
    background-color: rgb(141, 141, 141);
    font-family: var(--MainFontType);
    font-weight: 300;
    color: var(--MainFontColor);
    border: 1px solid orange;
}

.ConnectionAlertIcon {
    width: 20px;
    height: 20px;
    color: rgb(255, 166, 2);
}

@media screen and (max-width: 500px) {
	/*MOBILE SECTION*/

    #ShowOnlineStatusMainContainer {
        top: 360px;
        font-size: 14px;
        box-shadow: rgba(22, 22, 22, 0.747) 2px 2px 5px;
        z-index: 1;
    }
    
    .ConnectionAlertIcon {
        width: 20px;
        height: 20px;
    }
}