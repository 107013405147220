
#PayButton {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgb(75, 168, 98);
	border-radius: 25px;
	border: 1px dotted var(--MainFontColor);
	width: 130px;
	height: 30px;
	font-size: 20px;
    color: var(--MainFontColor);
	margin: 10px;
	cursor: pointer;
	font-weight: 300;
}

#CancelButton {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgb(108, 108, 108);
	border-radius: 25px;
	border: 1px dotted var(--MainFontColor);
	width: 130px;
	height: 30px;
	font-size: 20px;
    color: var(--MainFontColor);
	margin: 10px;
	cursor: pointer;
	font-weight: 300;
}

*#FakePayButton {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgb(128, 128, 128);
	border-radius: 25px;
	border: 1px dotted var(--MainFontColor);
	width: 250px;
	height: 32px;
	font-size: 13px;
	margin: 10px;
	text-align: center;
}

.EditOfferBtnsContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}



@media screen and (max-width: 450px) {
	/*MOBILE SECTION*/

}