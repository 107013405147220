#TopBar4MainDiv {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin: 0px;
	width: 95%;
	height: auto;
	font-family: var(--MainFontType);
}

#MainButtonsContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	margin-left: 0px;
	margin-right: 0px;
	width: auto;
	height: 50px;
	font-family: var(--MainFontType);
}

a:-webkit-any-link {
	text-decoration: none;
	cursor: pointer;
}

.LinkAndIconBoxInActive {
	width: 180px;
	height: 30px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	color: var(--MainFontColor);
	font-weight: 300;
	border: 1px solid gray;
	border-radius: 25px;
	margin: 0px 8px;
	padding: 0px 8px;
	cursor: pointer;
}

.LinkAndIconBoxInActive2 {
	width: 180px;
	height: 30px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	color: var(--MainFontColor);
	font-weight: 300;
	border: 1px solid gray;
	border-radius: 25px;
	margin: 0px 8px;
	padding: 0px 8px;
	cursor: pointer;
}

.LinkAndIconBoxActive {
	width: 180px;
	height: 30px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	border: 1px solid var(--MainFontColor);
	border-radius: 25px;
	background-color: rgb(102, 102, 102);
	color: var(--MainFontColor);
	font-weight: 300;
	margin: 0px 8px;
	padding: 0px 8px;
	cursor: pointer;
}

.LinkAndIconBoxActive:hover {
	background-color: rgb(88, 88, 88);
}

.LinkAndIconBoxInActive:hover {
	background-color: rgb(88, 88, 88);
}

.TopBar4LinkIcon {
	color: var(--MainFontColor);
	margin: 0px;
	width: 22px;
	height: 22px;
}

@media screen and (max-width: 500px) {
	/*MOBILE SECTION*/

	#TopBar4MainDiv {
		width: 100vw;
		height: auto;
		flex-wrap: wrap;
		margin-bottom: 0px;
	}

	#MainButtonsContainer {
		width: 100vw;
		height: 180px;
		flex-wrap: wrap;
		margin-bottom: 5px;
	}

	.LinkAndIconBoxInActive {
		flex-direction: column-reverse;
		width: 132px;
		height: 60px;
		margin: 5px 6px;
		font-size: 14px;
		border-radius: 15px;
	}

	.LinkAndIconBoxInActive2 {
		flex-direction: column-reverse;
		width: 295px;
		height: 60px;
		margin: 5px 6px;
		font-size: 14px;
		border-radius: 15px;
	}
	
	.LinkAndIconBoxActive {
		flex-direction: column-reverse;
		width: 132px;
		height: 60px;
		margin: 5px 6px;
		font-size: 14px;
		border-radius: 15px;
	}

	.LinkTextValue {
		margin: 6px;
	}

	.TopBar4LinkIcon {
		color: var(--MainFontColor);
		margin: 0px;
		width: 72px;
		height: 72px;
	}
	

	#LinkAndIconBoxFirmsBase {
		width: 290px;
		margin: 0px;
	}

}
