.WorkerDataEditContainer {
    min-width: 330px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px 3px;
}

.WorkerDetailsInputs {
    width: 280px;
    max-width: 280px;
	font-family: var(--MainFontType);
	background-color: rgb(100, 100, 100);
	color: var(--MainFontColor);
	border-radius: 20px;
	font-weight: 100;
	font-size: 15px;
	border: 1px solid rgb(148, 148, 148);
	text-align: center;
	margin: 5px;
	cursor: default;
}

.WorkerDetailsOptions {
    width: 280px;
    max-width: 280px;
	font-family: var(--MainFontType);
	background-color: rgb(100, 100, 100);
	color: var(--MainFontColor);
	border-radius: 20px;
	font-weight: 100;
	font-size: 15px;
	border: 1px solid rgb(148, 148, 148);
	text-align: center;
	margin: 5px;
	cursor: default;
}

.WelderMethodsContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	max-width: 250px;
}

.WorkerDataEditButtons {
    color: var(--MainFontColor);
    background-color: rgb(133, 133, 133);
    width: 100px;
    height: 23px;
    margin: 10px;
    border: 1px solid rgb(209, 208, 208);
    border-radius: 15px;
    cursor: pointer;
}

@media screen and (max-width: 500px) {
	/*MOBILE SECTION*/
}