#EditFieldsMainDiv {
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-family: var(--MainFontType);
}

#AddOfferEditFields {
	position: relative;
	top: 80px;
	display: flex;
	flex-direction: column;
	width: 600px;
}

.StepperHeader {
    color: var(--MainFontColor);
	position: relative;
	top: 40px;
	font-weight: 300;
}


.PhoneNumAddedToOffer {
    color: var(--MainFontColor);
    text-align: center;
	font-weight: 300;
	font-size: 18px;
	margin: 10px;
	cursor: pointer;
}

.PhoneNumAddedToOffer:hover .PhoneToolTip {
	visibility: visible;
}

.PhoneNumAddedToOffer .PhoneToolTip {
	visibility: hidden;
	position: relative;
	font-size: 15px;
	top: -1px;
	left: 10px;
	background-color: rgb(109, 109, 109);
	border-radius: 5px;
	padding: 5px;
}

.EditOfferHeader {
	align-self: center;
	font-family: var(--MainFontType);
	color: var(--MainFontColor);
	font-weight: 300;
	font-size: 35px;
}

.OfferDetailsInputs {
	font-family: var(--MainFontType);
	background-color: rgb(100, 100, 100);
	color: var(--MainFontColor);
	border-radius: 20px;
	font-weight: 100;
	font-size: 15px;
	border: 1px solid rgb(148, 148, 148);
	text-align: center;
	margin: 5px;
	cursor: default;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
    background-color: transparent !important;
}

#NaviBtns {
	width: auto;
	display: flex;
	align-items: center;
	justify-content: center;
}

.NaviBtnsEditFields {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100px;
	height: 25px;
	margin: 20px;
	border-radius: 20px;
	background-color: var(--MainButtonColor);
	font-size: 18px;
	font-weight: 300;
	color: var(--MainFontColor);
	cursor: pointer;
	box-shadow: 2px 2px 5px rgba(61, 61, 61, 0.829);
}

#SJProfileAplyConfirmation {
	text-align: center;
}

.NaviBtnsEditFields :hover {
	box-shadow: 2px 2px 5px rgba(90, 90, 90, 0.829);
}

.EditFieldsPhoneValues {
	font-weight: 100;
}


@media screen and (max-width: 500px) {
	/*MOBILE SECTION*/
	.EditOfferHeader {
		font-size: 30px;
	}

	#AddOfferEditFields {
		display: flex;
		flex-direction: column;
		width: 100vw;
		min-height: 1700px;
		height: auto;
	}
}