.chooseOrCloseContainer {
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.ClosePhoneOrSMSContainer {
    width: 40px;
    height: 40px;
    margin: 0px;
    padding: 0px;
    cursor: pointer;
}

.PhoneOrSMSButtonsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.PhoneAndSMSbtn {
    width: 140px;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    font-weight: 300;
    text-decoration: none;
    color: var(--MainFontColor);
    margin: 15px 10px;
    user-select: none;
    border: 1px solid var(--MainFontColor);
    border-radius: 20px;
    cursor: pointer;
}

.PhoneAndSMSbtn:hover {
    background-color: rgb(83, 83, 83);
}


.PhoneOrSMSContactNumber {
    font-family: var(--MainFontType);
    font-weight: 300;
}

@media screen and (max-width: 500px) {
	/*MOBILE SECTION*/

    .PhoneOrSMSContactNumber {
        font-size: 20px;
        font-family: var(--MainFontType);
        font-weight: 300;
    }

    .ClosePhoneOrSMSContainer {
        width: 30px;
        height: 30px;
    }
    
}