#SearchBarMainDiv {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 98vw;
	height: auto;
	font-family: var(--MainFontType);
	color: var(--MainFontColor);
	font-weight: 100;
	margin: 10px 0px;
}

#CompaniesSearchInput {
	background-color: rgb(77, 77, 77);
	border: none;
	border-bottom: 1px solid rgb(133, 133, 133);
	height: 28px;
	width: 450px;
	opacity: 1;
	transition: opacity 0.8s ease-in-out;
	color: var(--MainFontColor);
	padding: 0 5px;
	text-align: center;
	border-radius: 15px;
}

#CompaniesSearchInput::placeholder {
	font-size: 17px;
	font-family: var(--MainFontType);
	font-weight: 300;
	color: var(--MainFontColor);
}

#CompaniesSearchInput:focus {
	outline: none;
}

.FirmBaseSearchBarAndNavifation {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

@media screen and (max-width: 500px) {
	/*MOBILE SECTION*/

	#SearchBarMainDiv {
		position: relative;
		z-index: 2;
		top: 0px;
	}

	#CompaniesSearchInput {
		width: 300px;
	}

	.FirmBaseSearchBarAndNavifation {
		flex-direction: column;
	}
}
