.AdditionalFilterContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 15px 5px;
    padding: 5px;
    border-bottom: 1px dotted rgb(109, 104, 104);
}

.AdditionalFiltersConfirmBtnsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 15px 0px;
}

.AdditionalFiltersButton {
    color: var(--MainFontColor);
    font-weight: 100;
    background-color: rgb(75, 73, 73);
    padding: 6px 15px;
    border: none;
    border-radius: 15px;
    margin: 3px;
    cursor: pointer;
    border: 1px solid rgb(94, 93, 93);
}

.AdditionalFiltersButton:hover{
    scale: 1.05;
}

.AdditionalFiltersButtonActive {
    color: var(--MainFontColor);
    font-weight: 100;
    background-color: rgb(136, 116, 116);
    padding: 6px 15px;
    border: none;
    border-radius: 15px;
    margin: 3px;
    cursor: pointer;
}

.AdditionalFiltersButtonActive:hover{
    scale: 1.05;
}

.AdditionalFilterContainer {
    font-size: 22px;
    font-weight: 100;
    text-align: center;
}

@media screen and (max-width: 500px) {
	/*MOBILE SECTION*/
    .AdditionalFilterContainer {
        font-size: 19px;
    }

}