#TopBar2MainDiv {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background-color: var(--MainBackGroundColor);
	color: var(--MainFontColor);
	font-family: var(--MainFontType);
	width: 100%;
	height: auto;
	margin: 8px 0px;
}

#SearchInputsAndLogosContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 1250px;
	min-height: 90px;
	margin: 0px;
	padding: 5px;
	background-color: rgb(102, 102, 102);
	border-radius: 20px;
}

.SearchInputsAndMainButtonsContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
}

#SearchInputsContainer {
	width: 530px;
	height: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

#LocalizationContainerSmall {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	color: gray;
	border-bottom: 1px solid gray;
	width: 300px;
	height: 30px;
	text-align: center;
	margin:0px;
	background-color: rgb(77, 77, 77);
	border-radius: 15px;
}

#LocalizationContainerSmallFilled {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	color: gray;
	border-bottom: 1px solid gray;
	width: 300px;
	text-align: center;
	z-index: 1;
}

#SearchInput:focus {
	outline: none;
}

#SearchInputHided {
	visibility: hidden;
	opacity: 0;
	position: fixed;
}

#SearchValuesMainDiv {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 8vh;
}

#locationSearchPlaceholderContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.SingleWordDiv {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	flex-direction: row;
	background-color: var(--SecondFOntColor);
	border-radius: 10px;
	height: 20px;
	min-width: 95px;
	width: auto;
	padding: 0px 15px;
	max-width: 150px;
	margin: 5px 5px;
	cursor: pointer;
	font-weight: 100;
}

.SingleWord {
	width: 90%;
}

.KeyWordCloseBtn {
	margin-left: 3px;
	min-width: 17px;
	min-height: 17px;
}

#LabelAndSelect {
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	width: 450px;
	padding: 0px;
}

#RealSelect::-webkit-scrollbar {
	background-color: var(--MainBackGroundColor);
}

#RealSelect::-webkit-scrollbar-thumb {
	background-color: var(--SecondFOntColor);
	border-radius: 10px;
}

#MainFilterButtons {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 98%;
	height: auto;
	font-weight: 300;
	margin-top: 10px;
	padding: 0px;
}

#MainFiltersTitle {
	position: relative;
	top: 5px;
	color: var(--SecondFOntColor);
	margin: 10px;
}

#MainFiltersDiv {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 80%;
}

.IconsText {
	margin: 0 8px;
}

.SearchInputs {
	background-color: var(--MainBackGroundColor);
	border: none;
	border-bottom: 1px solid rgb(133, 133, 133);
	height: 30px;
	width: 300px;
	opacity: 1;
	transition: opacity 0.8s ease-in-out;
	color: var(--MainFontColor);
	padding: 0;
	text-align: center;
	font-size: 17px;
	background-color: rgb(77, 77, 77);
	border-radius: 15px;
	margin: 0px 2px;
	cursor: pointer;
}

.SearchInputs::placeholder {
	font-size: 16px;
	font-family: var(--MainFontType);
	font-weight: 100;
	color: var(--MainFontColor);
}

.SearchInputs:focus {
	outline: none;
}

.SearchInputs:hover {
	text-decoration: underline;
	text-decoration-color: rgb(129, 129, 129);
}


.MainInputsPlaceholders {
	font-size: 16px;
	font-family: var(--MainFontType);
	font-weight: 100;
	color: var(--MainFontColor);
	margin: 0px 5px;
}

.MainInputsPlaceholders:hover {
	text-decoration: underline;
	text-decoration-color: rgb(129, 129, 129);
}

.SearchInputIconsContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	width: 100%;
}

.searchInputIcons {
   width: 30px;
   height: 30px;
   color: rgb(76, 76, 76);
   margin: 0px;
}

#CountryAndCityDisplayContainer {
	display: flex;
	flex-direction: row;
}



@media screen and (max-width: 500px) {
	/*MOBILE SECTION*/

	#TopBar2MainDiv {
		width: 100%;
		position: relative;
		top: 0px;
		height: auto;
		margin-bottom: 5px;
	}

	#SearchInputsAndLogosContainer {
		width: 90vw;
		box-shadow: 2px 2px 5px rgba(61, 61, 61, 0.829);
	}

	.SearchInputs {
		width: 320px;
		height: 35px;
		border-radius: 20px;
		margin-bottom: 5px;
	}

	.SearchInputs::placeholder {
		font-weight: 100;
		font-size: 17px;
	}

	.MainInputsPlaceholders {
		font-size: 17px;
	}

	#LabelAndSelect {
		width: 100%;
	}

	.SearchInputsAndMainButtonsContainer {
		flex-direction: column;
	}

	#LocalizationContainerSmall {
		width: 320px;
		height: 35px;
		border-radius: 20px;
		font-weight: 100;
	}

	#LocalizationContainerSmallFilled {
		justify-content: flex-start;
		width: 320px;
		height: 35px;
		border-radius: 20px;
		font-size: 17px;
		overflow-x: scroll;
	}

	.SingleWordDiv {
		min-width: 95px;
	}

	.MapCloseButtonContainer {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
	}

	#MainFilterButtons {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		width: 100%;
		font-weight: 300;
		margin-top: 0px;
	}

	#SearchInputsContainer {
		flex-direction: column;
		margin-bottom: 5px;
	}

	#MainFiltersDiv {
		justify-content: flex-start;
		width: 100vw;
		overflow-x: scroll;
		min-height: 53px;
	}

	#MainFilterButtons::-webkit-scrollbar-track {
		width: 0px;
		height: 0px;
	}

	#MainFilterButtons::-webkit-scrollbar-thumb {
		width: 0px;
		height: 0px;
	}

	.KeyWordCloseBtn {
		position: relative;
		right: -8px;
		min-width: 17px;
		min-height: 17px;
	}

	#MainFiltersTitle {
		margin: 0px;
		color: var(--SecondFOntColor);
		font-size: 15px;
	}

	#SearchValuesMainDiv {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: flex-start;
		overflow-y: scroll;
		min-height: 25px;
	}

	#MagnifierAndInput {
		width: 93%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 10px;
	}

	.searchInputIcons {
		width: 32px;
		height: 32px;
		color: rgb(76, 76, 76);
		margin: 5px;
	 }
}
