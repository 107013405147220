.MultipleMarkerMainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    background-color: rgb(70, 70, 70);
    border-radius: 3px double white;
    color: var(--MainFontColor);
    padding: 0px;
    z-index: 10;
    cursor: pointer;
}

.MultipleMarkerMainContainer:hover {
    position: relative;
    background-color: rgb(102, 101, 101);
    z-index: 1000;
    box-shadow: 2px 5px 2px rgba(71, 71, 71, 0.692);
}

.MultipleMarkerOffersContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: auto;
    min-width: 150px;
    height: auto;
    max-height: 350px;
    padding: 6px;
    border-radius: 10px;
    background-color: rgb(70, 70, 70);
    border-radius:  13px;
    color: var(--MainFontColor);
    z-index: 100;
}

.MultipleMarkerOffersDisplayContainer {
    height: 100%;
    overflow-y: scroll;
}

.MultipleMarkerOffersDisplayContainer::-webkit-scrollbar {
    width: 8px;
    height: 0px;
}

.MultipleMarkerOffersDisplayContainer::-webkit-scrollbar-thumb {
	border: 1px solid rgb(189, 189, 189);
	border-radius: 20px;
	background-color: var(--SecondFOntColor);
}


.MultipleMarkerMainContainerValue {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 1px double var(--MainFontColor);
    margin: 0px;
}

.MultipleMarkerIconContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.MultipleMarkerCloseIcon {
    width: 18px;
    height: 18px;
}

.MultipleMarkerCloseIcon:hover {
  background-color: rgb(109, 109, 109);
  border-radius: 20px;
  cursor: pointer;
}

.MultipleMarkerMainContainer .MultipleOffersCityTip {
    visibility: hidden;
    width: 120px;
    background-color: var(--MainBackGroundColor);
    color: var(--MainFontColor);
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    border: 1px dotted var(--MainFontColor);
  
    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
  
    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
  }

  .MultipleMarkerMainContainer:hover .MultipleOffersCityTip {
    visibility: visible;
    opacity: 1;
  }

  .MultipleMarkerToolTipHeader {
    font-size: 14px;
    font-weight: 100;
  }

  .MultipleMarkerToolTipText {
    font-size: 12px;
    font-weight: 100;
  }

  .MultipleMarkerMainContainer .MultipleOffersCityTip::after {
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: var(--MainBackGroundColor) transparent transparent transparent;
  }
