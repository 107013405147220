.WorkerLogoUserPanel {
    height: 150px;
    width: 150px;
    cursor: pointer;
}

.SearchForJobStatusButtons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
	top: 15px;
    margin: 15px;
}

.JobStatusButton {
    height: 25px;
    width: 70px;
    margin: 0px 10px;
    border-radius: 15px;
    border: 1px solid rgb(243, 243, 243);
    background-color: rgb(94, 94, 94);
    color: var(--MainFontColor);
    cursor: pointer;
}

.JobStatusButton:hover {
    scale: 1.05;
}

.JobStatusButtonActive {
    height: 25px;
    width: 70px;
    margin: 0px 10px;
    border-radius: 15px;
    border: 2px solid rgb(243, 243, 243);
    background-color: rgb(129, 129, 129);
    color: var(--MainFontColor);
    cursor: pointer;
}

.JobStatusButtonactive:hover {
    scale: 1.05;
}

.WorkerAplicationsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 5px;
}

.NoUsersApplicationsInfoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.NoUsersApplicationText {
    font-weight: 100;
    color: rgb(163, 163, 163);
    text-decoration: underline;
}
