
.MarkerMainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 30px;
    border: 1px groove rgb(150, 161, 129);
    border-radius: 15px;
    background-color: rgb(70, 70, 70);
    cursor: pointer;
}

.MarkerMainContainer:hover {
    background-color: rgb(102, 101, 101);
    position: relative;
    z-index: 1000;
    box-shadow: 2px 5px 2px rgba(71, 71, 71, 0.692);
}

.PremiumMarkerMainContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 30px;
    border-radius: 20px;
    background-color: rgb(70, 70, 70);
    border: 5px double var(--MainFontColor);
    z-index: 99;
}


.PremiumMarkerMainContainer:hover {
  background-color: #555;
}

.LogoAndTextContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: 0px;
    user-select: none;
}

.TextContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.MarkerTitle {
    color: var(--MainFontColor);
    font-family: var(--MainFontType);
    font-weight: 300;
    margin: 0px;
}

.MarkerDescription {
    font-size: 9px;
    font-weight: 100;
    color: var(--MainFontColor);
    margin: 0px;
}

.MapIcon {
    width: 18px;
    height: 18px;
    color: var(--MainFontColor);
    margin-right: 5px;
    border: 1px solid var(--MainFontColor);
    border-radius: 50%;
}

.MapIconPremium {
  width: 18px;
  height: 18px;
  color: var(--MainFontColor);
  margin-right: 5px;
  border: 1px solid var(--MainFontColor);
  border-radius: 50%;
  animation: rotation 3.5s infinite linear;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  45% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-20deg) scale(1.1);
  }
  55% {
    transform: rotate(20deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.MarkerMainContainer .cityTip {
    visibility: hidden;
    width: 120px;
    background-color: var(--MainBackGroundColor);
    color: var(--MainFontColor);
    text-align: center;
    padding: 5px 0;
    border-radius: 16px;
    border: 1px dotted var(--MainFontColor);
  
    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
  
    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
  }

  .MarkerMainContainer:hover .cityTip {
    visibility: visible;
    opacity: 1;
  }

  .PremiumMarkerMainContainer .cityTip {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    border: 1px dotted var(--MainFontColor);
  
    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
  
    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
  }

  .PremiumMarkerMainContainer:hover .cityTip {
    visibility: visible;
    opacity: 1;
  }

  .MarkerMainContainer .cityTip::after {
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: var(--MainBackGroundColor) transparent transparent transparent;
  }

  .PremiumMarkerMainContainer .cityTip::after {
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: var(--MainBackGroundColor) transparent transparent transparent;
  }

