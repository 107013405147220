.UserOfferCardMainDiv {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 620px;
	height: auto;
	background-color: rgb(77, 76, 76);
	border-radius: 20px;
	margin: 10px 0px;
	box-shadow: 2px 2px 5px rgba(61, 61, 61, 0.829);
}

.userOfferCardLogo {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 10%;
	height: 100%;
	margin: 0px 18px;
}

.userOfferTitle {
	font-family: var(--MainFontType);
	font-weight: 200;
	font-size: 30px;
	flex-grow: 2;
	margin: 0px;
}

.DetailsMainDiv {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 80%;
	height: 100%;
}

.UserOfferRoleLogo {
	width: 70px;
	height: 70px;
}

.userOfferTitlePriceId {
	width: 100%;
}

.userOfferTitleAndPrice {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	width: 100%;
	cursor: pointer;
}

.userOfferDatesDiv {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;
	width: 100%;
	margin: 15px 0px;
	cursor: default;
}

.userOfferDates {
	font-family: var(--MainFontType);
	font-weight: 100;
	margin: 0px;
	cursor: default;
}

.userOfferCountry {
	font-weight: 400;
	margin: 0px 10px;
}

.statsAndActions {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	min-height: 30px;
	cursor: default;
}

.ActionButtonsMainContainer {
	display: flex;
	flex-direction: row;
}

.userOfferId {
	position: relative;
	top: -5px;
	font-family: var(--MainFontType);
	font-weight: 100;
	color: rgb(175, 175, 175);
	margin-top: 5px;
	cursor: pointer;
}

.userOfferStats {
	font-family: var(--MainFontType);
	font-weight: 100;
	font-size: 16px;
	margin: 0px;
}

.userOfferStatsDiv {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
}

#BtnsDiv {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	width: 95px;
	height: 75px;
}

#BtnsHided {
	visibility: hidden;
}

.actionBtn {
	margin: 2px;
}

.DeleteButton {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 13px;
	width: 85px;
	font-size: 16px;
	background-color: var(--MainButtonColor);
	border-radius: 20px;
	margin: 6px;
	padding:  7px 11px;
	font-weight: 300;
}

.DeleteButton:hover {
	cursor: pointer;
	scale: 1.08;
	font-weight: 400;
}

.DeleteButton2 {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 13px;
	width: 85px;
	font-size: 16px;
	background-color: var(--MainButtonColor);
	border-radius: 20px;
	margin: 6px;
	padding:  7px 11px;
	font-weight: 300;
}

.DeleteButton2:hover {
	cursor: pointer;
}

.RefreshButton {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 13px;
	width: 85px;
	font-size: 16px;
	background-color: rgb(126, 129, 100);
	border-radius: 20px;
	margin: 6px;
	padding:  7px 11px;
	font-weight: 300;
}

.RefreshButton:hover {
	cursor: pointer;
	scale: 1.08;
	font-weight: 400;
}

.RetrieveButton {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 13px;
	width: auto;
	font-size: 16px;
	background-color: rgb(126, 129, 100);
	border-radius: 20px;
	margin: 6px;
	padding:  7px 11px;
	font-weight: 300;
}

.RetrieveButton:hover {
	cursor: pointer;
	scale: 1.08;
	font-weight: 400;
}

.awaitingOffersButtons {
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.FinishPaymentDiv {
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.paymentStatusInfo {
	padding: 1px 10px;
	border-radius: 15px;
	background-color: rgb(121, 121, 121);
	cursor: pointer;
}

@media screen and (max-width: 500px) {
	/*MOBILE SECTION*/

	.userOfferTitleAndPrice {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: 100%;
		margin: 20px 0px;
	}

	.userOfferCardLogo {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 10%;
		height: 100%;
		margin: 0px 10px;
	}


	.UserOfferRoleLogo {
		min-width: 60px;
		min-height: 60px;
	}

	.UserOfferCardMainDiv {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 315px;
		height: auto;
	}

	.userOfferDatesDiv {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: 100%;
		margin: 15px 0px;
	}

	.userOfferDates {
		font-size: 14px;
		margin: 2px;
	}

	.userOfferStats {
		font-size: 14px;
		margin: 2px;
	}

	.userOfferStatsDiv {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		width: 100%;
	}

	.Salary {
		font-size: 16px;
	}

	.userOfferTitle {
		text-align: center;
		font-family: var(--MainFontType);
		font-weight: 300;
		font-size: 22px;
		flex-grow: 2;
		margin: 5px;
	}

	.RefreshButton {
		height: 10px;
		font-weight: 300;
		margin: 5px 3px;
		padding: 8px 11px;
	}

	.DeleteButton {
		height: 10px;
		font-weight: 300;
		margin: 5px 3px;
		padding: 8px 11px;
	}

	.DeleteButton2 {
		height: 10px;
		font-weight: 300;
		margin: 5px 3px;
		padding: 8px 11px;
	}

	.userOfferId {
		text-align: center;
		font-size: 14px;
	}

	.RetrieveButton {
		white-space: nowrap;
	}
	
}
