#PopUpMainDiv {
	position: absolute;
	top: 5%;
	width: 85vw;
	height: 80vh;
	background-color: rgb(56, 56, 56);
	border: 2px solid var(--MainFontColor);
	box-shadow: 9px 15px 15px rgb(0, 0, 0);
	border-radius: 20px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	font-family: var(--MainFontType);
}

#OfferPreview {
	position: absolute;
	top: -250px;
	width: 85vw;
	height: 80vh;
	background-color: rgb(56, 56, 56);
	border: 1px dotted var(--MainFontColor);
	box-shadow: 5px 5px 15px rgb(0, 0, 0);
	border-radius: 20px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	z-index: 898;
}

#OfferDetailsDiv {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	border: none;
	background-color: rgb(56, 56, 56);
	box-shadow: none;
}

#AddDate {
	position: absolute;
	left: 15px;
	top: 0px;
}

#Preview {
	position: absolute;
	margin-left: auto;
	margin-right: auto;
	top: 10%;
	font-size: 90px;
	color: rgba(172, 171, 171, 0.493);
	text-align: center;
	z-index: -1;
}

#SmallMainDiv {
	position: absolute;
	width: auto;
	min-width: 400px;
	height: auto;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	padding: 25px;
	background-color: rgb(56, 56, 56);
	border: 1px solid rgb(87, 87, 87);
	box-shadow: 1px 1px 1000px 700px rgba(71, 68, 68, 0.842);
	border-radius: 20px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	z-index: 898;
	font-family: var(--MainFontType);
	color: var(--MainFontColor);
}

#EditUserFields {
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.PopUpModalTitle {
	font-size: 22px;
	font-weight: 100;
}

.PopUpModalSubTitle {
	font-weight: 100;
	font-size: 15px;
}


#Hint {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--SecondFOntColor);
	border-radius: 10px;
	padding: 4px 10px;
	margin: 10px;
	height: 15px;
}

#RealSelect {
	cursor: pointer;
	opacity: 0;
}

.SelectOption {
	background-color: var(--MainBackGroundColor);
	color: var(--MainFontColor);
	font-weight: 100;
	font-size: 17px;
}

#ModalLogo {
	font-family: var(--SecondFontType);
	font-size: 40px;
	cursor: default;
	margin: 0px;
	text-shadow: 1px 2px 5px rgb(13, 13, 13);
}

.RegisterCompanyTitle {
	font-family: var(--MainFontType);
	font-weight: 300;
}

.EditInputs {
	background-color: var(--MainBackGroundColor);
	border: 1px solid var(--MainFontColor);
	border-radius: 20px;
	height: 22px;
	width: 300px;
	color: var(--MainFontColor);
	text-align: center;
	margin-bottom: 15px;
}

#CountryChooseTitle {
	font-size: 26px;
	font-weight: 100;
	margin: 2px;
	margin-bottom: 15px;
}

.ConfirmBtns {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 72px;
	height: 30px;
	margin: 4px;
	background-color: var(--MainBackGroundColor);
	color: var(--MainFontColor);
	border: 1px solid var(--MainFontColor);
	border-radius: 20px;
	font-family: var(--MainFontType);
	font-size: 16px;
	padding: 3px 10px;
	cursor: pointer;
}

.ConfirmBtns:hover {
	background-color: rgb(85, 85, 85);
}


#CompanyInputAndTitle {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

#CompanyDescriptionInput {
	min-width: 500px;
	width: 100%;
	height: 200px;
	margin: 10px 0px;
	background-color: rgb(80, 80, 80);
	font-family: var(--MainFontType);
	font-size: 16px;
	color: var(--MainFontColor);
	font-weight: 100;
	border-radius: 15px;
	padding: 5px;
}

#CompanyDescriptionInput::placeholder{
	font-weight: 300;
}

#DescriptionInput::-webkit-scrollbar {
	background-color: var(--MainBackGroundColor);
	border-radius: 20px;
	width: 14px;
}

#DescriptionInput::-webkit-scrollbar-thumb {
	background-color: gray;
	border-radius: 20px;
}

.ModalTitle {
	text-align: center;
	font-size: 23px;
	font-weight: 200;
	margin: 7px 5px;
}

#ChooseAccountTypeContainer {
	width: 100%;
	height: 40px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.ChooseAccountTypeBtn {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 120px;
	height: 30px;
	font-weight: 300;
	margin: 20px;
	border: 1px solid gray;
	border-radius: 20px;
	cursor: pointer;
}

.ChooseAccountTypeBtnFilled {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 120px;
	height: 30px;
	font-weight: 300;
	margin: 20px;
	border: 1px solid rgb(247, 219, 129);
	border-radius: 20px;
	cursor: pointer;
	background-color: rgb(104, 104, 104);
}

#DeleteConfirmDiv {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.ModalMessage {
	width: 100%;
	font-size: 18px;
	font-weight: 100;
	word-wrap: break-word;
	text-align: center;
	margin: 12px;
}

.ChoosedAccountInfo {
	text-align: center;
	padding: 2px 10px;
	background-color: rgb(87, 87, 87);
	font-weight: 200;
	border-radius: 20px;
	margin: 4px;
}

.orderPictureUploaderButtons {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

#FakePrefixSelect {
	font-weight: 300;
	position: relative;
	top: 2px;
	width: 1px;
	height: 1px;
	font-size: 19px;
}

.prefixValue {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 45px;
	border-radius: 20px;
	border: 1px solid var(--MainFontColor);
}

#CountriesList {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	width: 800px;
	height: 550px;
}

.LogoUploaderForm {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100px;
}

.FileChooseButton::-webkit-file-upload-button {
	width: 120px;
	height: 30px;
	margin: 4px;
	background-color: var(--MainBackGroundColor);
	color: var(--MainFontColor);
	border: 1px solid var(--MainFontColor);
	border-radius: 20px;
	font-family: var(--MainFontType);
	font-size: 16px;
	padding: 3px 10px;
	cursor: pointer;
}

.UserLogoHints {
	font-weight: 100;
	margin: 5px;
}

#PhoneNumberHeader {
	font-weight: 300;
	margin: 1px 120px;
}

#popularCountriesContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	margin-top: 10px;
	height: auto;
}

.PopularDestinationsButtonsContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	margin: 10px;
}

#filteredCountriesButtonsContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;
	overflow-x: scroll;
}

#filteredCountriesButtonsContainer::-webkit-scrollbar {
	background-color: transparent;
}

#filteredCountriesButtonsContainer::-webkit-scrollbar-thumb {
	border: 1px solid rgb(189, 189, 189);
	border-radius: 20px;
	background-color: var(--SecondFOntColor);
}

.SearchLocationsInputs {
	width: 400px;
	height: 30px;
	font-family: var(--MainFontType);
	background-color: rgb(100, 100, 100);
	color: var(--MainFontColor);
	border-radius: 35px;
	font-weight: 100;
	font-size: 15px;
	border: 1px solid rgb(148, 148, 148);
	text-align: center;
	margin: 5px;
	cursor: pointer;
}

.PopularDestianationButton {
	height: 30px;
	width: 80px;
	border-radius: 15px;
    margin: 5px;
	background-color: rgb(100, 100, 100);
	border: 1px solid rgb(148, 148, 148);
	color: var(--MainFontColor);
	font-weight: 100;
	font-family: var(--MainFontType);
	cursor: pointer;
}

.SearchLocationsInputs::placeholder {
	color: var(--MainFontColor);
}

@media screen and (max-width: 500px) {
	/*MOBILE SECTION*/

	#SmallMainDiv {
		position: absolute;
		width: auto;
		min-width: 280px;
		height: auto;
		padding: 10px;
		background-color: rgb(56, 56, 56);
		border: 1px solid rgb(87, 87, 87);
		box-shadow: 1px 1px 1000px 300px rgba(102, 100, 100, 0.959);
		border-radius: 20px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-direction: column;
		z-index: 898;
		font-family: var(--MainFontType);
		color: var(--MainFontColor);
	}

	#ModalLogo {
		font-family: var(--SecondFontType);
		font-size: 40px;
		cursor: default;
		margin: 5px;
	}

	.PopularDestianationButton {
        height: 25px;
        width: 80px;
        border-radius: 15px;
        margin: 5px;
        background-color: rgb(100, 100, 100);
        border: 1px solid rgb(148, 148, 148);
        color: var(--MainFontColor);
        font-weight: 100;
        font-family: var(--MainFontType);
        cursor: pointer;
    }

	#CompanyDescriptionInput {
		min-width: 250px;
		width: 90%;
		height: 200px;
		margin: 10px 10px;
		background-color: rgb(80, 80, 80);
		font-family: var(--MainFontType);
		font-size: 16px;
		color: var(--MainFontColor);
		font-weight: 100;
		border-radius: 15px;
		padding: 5px;
	}

	#EditUserFields {
		width: 100%;
		height: auto;
		max-height: 660px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	#Preview {
		top: 50%;
		font-size: 20px;
		color: rgb(46, 46, 46);
	}

	#CountryChooseTitle {
		font-size: 18px;
		font-weight: 100;
		margin: 2px;
		margin-bottom: 5px;
	}

	#AddDate {
		position: absolute;
		left: 50%;
		transform: translate(-50%);
		top: 90%;
		font-size: 13px;
		color: rgb(44, 44, 44);
		color: var(--MainFontColor);
	}

	#CloseBtn {
		position: absolute;
		right: 2%;
		top: 1.5%;
		height: 20px;
		width: 20px;
		cursor: pointer;
	}

	#ChooseAccountTypeContainer {
		height: 70px;
	}

	#ModalTitle {
		text-align: center;
		font-size: 22px;
	}

	#ModalInfoTitle {
		font-size: 24px;
	}

	#DescriptionInput {
		min-width: 300px;
		width: 300px;
	}

	#DescriptionInput::-webkit-scrollbar {
		width: 5px;
	}

	.EditInputs {
		width: 220px;
	}

	.ModalMessage {
		text-align: center;
	}

	#CountriesList {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 90vw;
		min-height: 510px;
		height: 510px;
	}

	#PhoneNumberHeader {
		margin-left: 80px;
		font-weight: 300;
	}

	.ChoosedAccountInfo {
		margin: 0px;
	}

	.SearchLocationsInputs {
		width: 280px;
		height: 30px;
	}

	#filteredCountriesButtonsContainer::-webkit-scrollbar {
		display: none;
	}

	#filteredCountriesButtonsContainer {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		height: auto;
		width: 80%;
		margin: 5px;
		overflow-x: scroll;
	}

}
