#ApplicationsModalMainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: auto;
}

#ApplicationsModalDisplayContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 800px;
    max-height: 1000px;
    overflow-y: scroll;
}

#ApplicationsModalDisplayContainer::-webkit-scrollbar {
	background-color: transparent;
}

#ApplicationsModalDisplayContainer::-webkit-scrollbar-thumb {
	border: 1px solid rgb(189, 189, 189);
	border-radius: 20px;
	background-color: var(--SecondFOntColor);
}

#AplicationMainContainer {
    font-weight: 100;
    width: 550px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(88, 88, 88);
    border: 1px solid rgb(116, 116, 116);
    border-radius: 20px;
    padding: 10px 5px;
    margin: 10px 0px;
    cursor: pointer;
}

.AplicationsDetailsSubContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 0px;
}

.AplicationDetailsText {
    margin: 5px 1px;
}

#ApplicationDropDownIcon {
    width: 50px;
    height: 50px;
    cursor: pointer;
}

@media screen and (max-width: 500px) {
	/*MOBILE SECTION*/
    #ApplicationsModalDisplayContainer {
        width: 330px;
        max-height: 700px;
    }

    #AplicationMainContainer {
        width: 300px;
    }

    .AplicationDetailsText {
        font-size: 13px;
    }
    
}