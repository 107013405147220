#OfferDetailsDiv {
	background-color: var(--MainBackGroundColor) !important;
	width: 100%;
	min-width: 1400px;
	height: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

#FirstDetails {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 95%;
	height: 250px;
	display: flex;
	background-color: #555555;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='100' y1='33' x2='100' y2='-3'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='100' y1='135' x2='100' y2='97'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23484848' fill-opacity='0.6'%3E%3Crect x='100' width='100' height='100'/%3E%3Crect y='100' width='100' height='100'/%3E%3C/g%3E%3Cg fill-opacity='0.5'%3E%3Cpolygon fill='url(%23a)' points='100 30 0 0 200 0'/%3E%3Cpolygon fill='url(%23b)' points='100 100 0 130 0 100 200 100 200 130'/%3E%3C/g%3E%3C/svg%3E");
	border-radius: 10px;
	margin-bottom: 20px;
	padding: 5px;
	box-shadow: 2px 2px 5px rgba(61, 61, 61, 0.829);
}

#DetailsDiv1 {
	width: 90%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
}

#DescriptionContainer {
	border-radius: 10px;
	width: 95%;
	height: 500px;
	padding: 5px;
	font-family: var(--MainFontType);
	font-size: 18px;
	overflow-y: scroll;
	color: var(--MainFontColor);
	background-color: rgb(78, 78, 78);
	margin-bottom: 20px;
	box-shadow: 2px 2px 5px rgba(61, 61, 61, 0.829);
}

#DescriptionContainer::-webkit-scrollbar {
	background-color: var(--SecondFOntColor);
	width: 10px;
	border-radius: 5px;
}

#DescriptionContainer::-webkit-scrollbar-thumb {
	background-color: rgb(43, 43, 43);
	border-radius: 5px;
}

.DetailContainer {
	width: 250px;
	height: 50px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-family: var(--MainFontType);
	background-color: rgb(78, 78, 78);
	border-radius: 10px;
	color: var(--MainFontColor);
	border: 1px solid rgb(104, 104, 104);
	box-shadow: 2px 2px 5px rgba(61, 61, 61, 0.829);
}

.DetailedContanerLogos {
	width: 30px;
	height: 30px;
}

.DetailContainerText {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin: 4px;
	font-size: 14px;
	font-weight: 200;
	white-space: initial;
}

.ApplyRegisterButtonText {
	font-family: var(--SecondFontType);
	color: var(--MainFontColor);
	font-size: 18px;
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 120px;
	max-width: auto;
	height: 25px;
	padding: 0px 10px;
	border: 1px solid rgb(3, 179, 82);
	background-color: #638b52;
	border-radius: 15px;
	cursor: pointer;
	text-decoration: none;
	margin: 0px 5px;
}

.DetailedOfferRoleLogo {
	align-self: center;
	justify-self: center;
	position: relative;
	top: 55px;
	color: var(--MainFontColor);
	min-width: 80px;
	max-width: 140px;
	min-height: 80px;
	max-height: 120px;
	border: 1px solid var(--MainFontColor);
	border-radius: 50%;
	z-index: 389;
	background-color: rgb(78, 78, 78);
	box-shadow: 5px 2px 10px rgb(20, 20, 20);
}

.DetailedUserRoleLogo {
	min-width: 80px;
	max-width: 140px;
	min-height: 80px;
	max-height: 120px;
	align-self: center;
	justify-self: center;
	color: var(--MainFontColor);
	width: 33%;
	border: 1px solid var(--MainFontColor);
	border-radius: 10%;
	z-index: 389;
	background-color: rgb(110, 110, 110);
	box-shadow: 5px 2px 10px rgb(20, 20, 20);
}

#TitleDiv {
	position: relative;
	top: 10px;
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 98%;
	justify-content: space-between;
	height: 60px;
	padding: 0px 10px;
	font-family: var(--MainFontType);
	font-size: 30px;
	color: var(--MainFontColor);
}

#DetailedOfferTitle {
	width: 90%;
	height: 100px;
	word-wrap: break-word;
	font-size: 32px;
	font-weight: 200;
	text-shadow: 3px 3px 4px black;
}

#Title {
	font-family: var(--MainFontType);
	font-weight: 300;
	color: var(--MainFontColor);
	font-size: 38px;
	height: 45px;
	width: auto;
	padding: 0px 12px;
	background-color: var(--SecondFOntColor);
	border-radius: 20px;
}

#ContactDiv {
	width: 96%;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	color: var(--MainFontColor);
	background-color: rgb(78, 78, 78);
	border-radius: 10px;
	box-shadow: 2px 2px 5px rgba(61, 61, 61, 0.829);
}

#CompanyInfoContainer {
	width: 96%;
	height: auto;
	padding: 10px 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 10px 0px;
	color: var(--MainFontColor);
	background-color: rgb(78, 78, 78);
	border-radius: 10px;
	box-shadow: 2px 2px 5px rgba(61, 61, 61, 0.829);
}

#CompanyInfoDescriptionContainer {
	width: 96%;
	height: auto;
	padding: 10px 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: var(--MainFontType);
	font-weight: 100;
	text-align: center;
	margin: 10px 0px;
	color: var(--MainFontColor);
	background-color: rgb(78, 78, 78);
	border-radius: 10px;
}

#CompanyInfoDescriptionContainerHidden {
	height: 0px;
	width: 0px;
	visibility: hidden;
	display: none;
	opacity: 0;
	margin: 0px;
} 

#CompanyInfoMiniLogoContainer {
	width: 96%;
	height: auto;
	padding: 10px 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: var(--MainFontType);
	font-weight: 100;
	text-align: center;
	margin: 10px 0px;
	color: var(--MainFontColor);
	background-color: rgb(78, 78, 78);
	border-radius: 10px;
	box-shadow: 2px 2px 5px rgba(61, 61, 61, 0.829);
}

.OfferDetailsShowCompanyDetailsBtn {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	height: 30px;
	width: 150px;
	font-family: var(--MainFontType);
	font-weight: 100;
	color: var(--MainFontColor);
	background-color: transparent;
	border: 1px solid var(--MainFontColor);
	border-radius: 20px;
	cursor: pointer;
}

.OfferDetailsDisplayMiniLogo {
	font-family: var(--SecondFontType);
	margin: 0px;
	cursor: pointer;
	user-select: none;
}

#DescriptionTitle {
	position: relative;
	top: -22px;
	left: 5px;
	font-weight: 300;
}

#CompanyName {
	display: flex;
}

.BackBtnLogoAndCountryContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.BackToMainBtn {
	color: var(--MainFontColor);
	width: 45px;
	height: 45px;
	cursor: pointer;
}

#CompanyPrefix {
	font-family: var(--MainFontType);
	font-weight: 200;
}

#CompanyTitle {
	font-weight: 100;
}

#Salary {
	font-weight: 100;
}

#CountryTitle {
	font-size: 30px;
	margin: 0px;
	font-family: var(--MainFontType);
	color: var(--MainFontColor);
	font-weight: 200;
	text-shadow: 5px 5px 5px black;
}

#CityName {
	font-size: 13px;
	margin: 0px;
	font-family: var(--MainFontType);
	color: var(--MainFontColor);
	font-weight: 100;
	text-shadow: 5px 5px 5px black;
}

#Description {
	position: relative;
	left: 5px;
	top: -20px;
	font-weight: 100;
	font-size: 18px;
}

#FormattedText {
	white-space: pre-wrap;
	overflow-wrap: break-word;
	font-weight: 100;
	font-family: var(--MainFontType);
	font-size: 18px;
}

#BadUrl {
	font-family: var(--MainFontType);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	position: absolute;
	text-align: center;
	color: var(--MainFontColor);
	width: 100vw;
	height: 100vh;
	z-index: 200;
	background-color: var(--MainBackGroundColor);
}

#BackLogo {
	font-weight: 300;
	cursor: pointer;
}

#BadUrlInfo {
	font-weight: 100;
}

#LogoDiv {
	background-color: var(--MainButtonColor);
	border-radius: 30px;
	padding: 5px;
}

#OfferDetailPhoneNumber {
	color: var(--MainFontColor);
	background-color: transparent;
	font-size: 17px;
	font-weight: 400;
	font-family: var(--MainFontType);
	font-weight: 200;
	border: none;
	cursor: pointer;
}

#OfferDetailEmail {
	font-family: var(--MainFontType);
	font-weight: 200;
}

.OfferDetailsContactsContainers {
	height: 30px;
	width: 230px;
	margin: 10px 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid var(--MainFontColor);
	border-radius: 20px;
	cursor: pointer;
}

.OfferDetailsContactIcons {
	color: var(--MainFontColor);
	text-decoration: none;
	width: 20px;
	height: 20px;
	margin: 0px 8px;
}

#detailedOfferFactoryLogo {
	color: rgb(240, 93, 93);
}

#detailedOfferExperienceLogo {
	color: rgb(133, 133, 235);
}

#detailedOfferAddDateLogo {
	color: rgb(98, 219, 98);
}

#detailedOfferSalaryLogo {
	color: rgb(231, 121, 69);
}

@media screen and (max-width: 500px) {
	/*MOBILE SECTION*/

	#OfferDetailsDiv {
		height: auto;
		min-width: 50px;
		width: 100%;
		margin: 5px 0px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	#FirstDetails {
		height: 180px;
	}

	.ApplyRegisterButtonText {
		font-size: 13px;
	}

	#DetailsDiv1 {
		width: 95%;
		min-width: 50px;
		height: 120px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: center;
	}

	.DetailContainer {
		flex-direction: column;
		margin: 3px 3px;
		height: 57px;
		width: auto;
		min-width: 145px;
	}

	.DetailContainerText {
		font-size: 11px;
		margin: 1px;
	}

	#DescriptionContainer {
		height: 300px;
	}

	#DescriptionContainer::-webkit-scrollbar {
		background-color: var(--SecondFOntColor);
		width: 1px;
		border-radius: 5px;
	}
	

	#Description {
		word-wrap: break-word;
		width: 90vw;
		font-weight: 100;
		font-size: 15px;
	}

	#DescriptionTitle {
		top: -15px;
		font-size: 20px;
		font-weight: 100;
	}

	.DetailedOfferRoleLogo {
		position: relative;
		min-width: 38px;
		min-height: 38px;
		top: 15px;
		box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.418);
	}

	.DetailedUserRoleLogo {
		position: relative;
		top: 5px;
		min-width: 70px;
		max-width: 70px;
		min-height: 60px;
		max-height: 60px;
		box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.118);
	}

	#TitleDiv {
		position: relative;
		top: 15px;
		width: 97%;
		height: 90px;
		text-align: left;
	}

	#DetailedOfferTitle {
		width: 276px;
		font-size: 19px;
	}

	#OfferDetailPhoneNumber {
		font-size: 12px;
	}

	#OfferDetailEmail {
		font-size: 12px;
	}

	#FormattedText {
		font-size: 16px;
	}

	#CountryTitle {
		font-size: 20px;
		text-shadow: 5px 5px 5px black;
	}

	#CityName {
		top: 33px;
		font-size: 11px;
	}

	.DetailedContanerLogos {
		margin-bottom: 1px;
		min-width: 20px;
		min-height: 20px;
	}

	.OfferDetailsContactIcons {
		width: 15px;
		height: 15px;
		margin: 0px 8px;
	}
}
