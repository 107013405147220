#ConfirmationModalMainContainer {
	width: 100%;
	height: auto;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	overflow:hidden;
}

#ConfirmBtn {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgb(112, 112, 112);
	width: 90px;
	height: 30px;
	border-radius: 15px;
	cursor: pointer;
	margin: 5px;
}

#ConfirmBtn:hover {
	scale: 1.05;
}

#ConfirmBtnText {
	font-family: var(--MainFontType);
	font-weight: 300;
}

@media screen and (max-width: 500px) {
	/*MOBILE SECTION*/
.ModalTitle {
	font-size: 20px;
}

}