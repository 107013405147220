#AnimationMainDiv {
	min-width: 400px;
	max-width: 400px;
	max-height: 400px;
	min-height: 400px;
	display: flex;
	align-items: center;
	justify-content: center;
}

#AnimationMainDivSmall {
	min-width: 100px;
	max-width: 100px;
	max-height: 100px;
	min-height: 100px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

#AnimatedWrench {
	width: 100px;
	height: 100px;
	color: var(--MainFontColor);
	animation: wave 1.5s linear infinite;
}

#AnimatedWrenchMini {
	width: 50px;
	height: 50px;
	color: var(--MainFontColor);
	animation: wave 1.5s linear infinite;
}

#ChoosingOption {
	position: absolute;
}

#ChoosingOptionWrench {
	width: 50px;
	height: 50px;
	margin-left: 500px;
	color: var(--MainFontColor);
	animation: wave 1.5s linear infinite;
	opacity: 0.7;
}

@keyframes wave {
	0% {
		transform: rotate(120deg) translateX(60px) translateY(30px);
	}
	50% {
		transform: rotate(160deg) translateX(60px) translateY(10px);
	}
	100% {
		transform: rotate(120deg) translateX(60px) translateY(30px);
	}
}

@media screen and (max-width: 500px) { 
	/*MOBILE SECTION*/

	#AnimationMainDiv {
		min-width: 200px;
	    max-width: 200px;
    	max-height: 100px;
    	min-height: 100px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		transform: scale(0.5);
		margin-top: 200px;
	}

	#ChoosingOptionWrench {
		width: 50px;
		height: 50px;
		margin-left: 90px;
		margin-top: 100px;
		color: var(--MainFontColor);
		animation: wave 1.5s linear infinite;
	}
	
}
