#Stepper0Div {
	position: relative;
	top: 50px;
	width: 900px;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px dotted rgb(109, 109, 109);
	font-family: var(--MainFontType);
}

.stepsArrows {
	position: relative;
	transform: scale(2.5);
	color: rgb(109, 109, 109);
}

.activeStep {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgb(173, 173, 173);
	width: 45px;
	height: 45px;
	border-radius: 50%;
	color: rgb(48, 48, 48);
	border: 1px solid var(--MainFontColor);
	font-weight: 400;
	box-shadow: 2px 2px 5px rgba(61, 61, 61, 0.829);
}

.simpleOfferDiv {
	margin: 90px;
	border-bottom: 1px dotted rgb(109, 109, 109);
}
.activeStepInfo {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgb(155, 155, 155);
	color: rgb(48, 48, 48);
	border-radius: 25px;
	width: 170px;
	border: 1px solid rgb(233, 233, 233);
	font-weight: 400;
	box-shadow: 2px 2px 5px rgba(61, 61, 61, 0.829);
}

.stepNumber {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgb(90, 90, 90);
	width: 45px;
	height: 45px;
	border-radius: 50%;
	font-weight: 100;
	box-shadow: 2px 2px 5px rgba(61, 61, 61, 0.829);
}
.allSteps {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.stepInfo {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgb(82, 82, 82);
	border-radius: 25px;
	width: 170px;
	height: 25px;
	font-weight: 200;
	box-shadow: 2px 2px 5px rgba(61, 61, 61, 0.829);
}

.confirmedStep {
	color: rgb(63, 182, 63);
}

.confirmedStep1 {
	color: rgb(63, 182, 63);
}

.nextStepPlaceholder {
	width: 20px;
	height: 20px;
}

#displayChoosedOption {
	position: absolute;
	top: 140px;
}

@media screen and (max-width: 500px) {
	/*MOBILE SECTION*/

	#Stepper0Div {
		top: 60px;
		overflow-x: scroll;
		width: 100vw;
	}

	.allSteps {
		font-size: 12px;
		padding: 0px;
	}

	.activeStepInfo {
		width: 100px;
		min-width: auto;
		height: 20px;
		margin: 8px 2px;
	}

	.stepInfo {
		width: 100px;
		min-width: auto;
		height: 20px;
		margin: 8px 2px;
	}

	.activeStep {
		width: 25px;
		height: 25px;
		font-size: 18px;
	}

	.stepNumber {
		width: 25px;
		height: 25px;
		font-size: 18px;
	}

	.stepsArrows {
		position: relative;
		top: -20px;
		transform: scale(1.6);
		color: rgb(129, 129, 129);
	}

	.confirmedStep {
		color: rgb(63, 182, 63);
	}

	.confirmedStep1 {
		color: rgb(63, 182, 63);
	}

	.nextStepPlaceholder {
		width: 10px;
		height: 10px;
	}
}
