.CopiedEmailTip {
    position: absolute;
    font-weight: 300;
    padding: 3px 15px;
    border-radius: 20px;
    background-color: gray;
    animation: fadeIn 1s;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }