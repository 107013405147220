.ComercialsInfoMainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 600px;
    height: auto;
    padding: 10px;
}

.ComercialInfoHeaders {
    color: var(--MainFontColor);
    font-family: var(--MainFontType);
    font-weight: 100;
    text-align: center;
}