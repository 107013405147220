.MarkerSingleOfferMainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 250px;
    background-color: rgb(94, 94, 94);
    margin: 6px 0px;
    padding: 4px;
    border-radius: 5px;
}

.MarkerSingleOfferHeader {
    font-size: 13px;
    font-weight: 400;
    font-family: var(--MainFontType);
}

.MarkerSingleOfferCity {
    font-size: 10px;
}

.MarkerSingleOfferSalary {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-weight: 500;
    width: 100px;
    background-color: rgb(112, 112, 112);
    color: rgb(86, 232, 86);
}