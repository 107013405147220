#LoggedUserMainDiv {
	width: 100%;
	height: auto;
	min-height: 110vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	background-color: var(--MainBackGroundColor);
	font-family: var(--MainFontType);
	color: var(--MainFontColor);
}

#AwaitingOffersMainDiv {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	margin-bottom: 40px;
	height: auto;
	overflow-y: scroll;
	border-radius: 5px;
	width: 680px;
}

#AwaitingOffersMainDiv::-webkit-scrollbar {
	background-color: var(--MainBackGroundColor);
	width: 10px;
	border-radius: 5px;
}

#AwaitingOffersMainDiv::-webkit-scrollbar-thumb {
	background-color: rgb(92, 92, 92);
	border-radius: 5px;
	border: 1px solid gray;
}

#AwaitingOffersHeader {
	font-weight: 300;
	font-size: 32px;
}

#UserProfileInfoDiv {
	/*Nazwa i mail*/
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 20px;
	width: 80%;
	height: 10%;
}

#UserOffersTypeHeader {
	height: auto;
	padding: 10px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
}

#UserOffersDiv {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	width: 80vw;
	height: 700px;
	background-color: var(--MainBackGroundColor);
	overflow: hidden;
}

#LogedUserAuthDiv {
	width: 100%;
	height: auto;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

#VerifyInfoDiv {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 80vh;
	color: var(--MainFontColor);
	background-color: var(--MainBackGroundColor);
	text-align: center;
}

.VerifyInfoText {
	width: auto;
	font-family: var(--MainFontType);
	font-weight: 200;
	color: var(--MainFontColor);
}

.VerifyInfoText2 {
	font-family: var(--MainFontType);
	font-weight: 200;
	color: var(--MainFontColor);
	font-size: 20px;
}

#ConfirmVeryfication {
	background-color: rgb(196, 81, 81);
	border-radius: 30px;
	padding: 0px 15px;
	font-size: 18px;
	font-family: var(--MainFontType);
	font-weight: 200;
	margin: 15px;
}

#ConfirmVeryfication:hover {
	cursor: pointer;
}

#DescriptionEditDiv {
	display: flex;
	align-items: center;
}

#EditDescriptionMainDiv {
	display: flex;
	align-items: center;
}

#MyOffersDisplay {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 90%;
	overflow-y: scroll;
	border-radius: 5px;
}

#MyOffersDisplay::-webkit-scrollbar {
	background-color: var(--MainBackGroundColor);
	width: 12px;
	border-radius: 5px;
}

#MyOffersDisplay::-webkit-scrollbar-thumb {
	background-color: rgb(92, 92, 92);
	border-radius: 5px;
	border: 1px solid gray;
}

#MyOffersDisplay::-webkit-scrollbar-thumb:hover {
	cursor: pointer;
}

#EmailInfo {
	display: flex;
	align-items: center;
	justify-content: center;
}

#emailTitle {
	position: relative;
	left: -5px;
	font-size: 18px;
	color: rgb(160, 160, 160);
	font-weight: 300;
	cursor: pointer;
}

#premiumOffersValue {
	color: var(--MainFontColor);
}

#emailValue {
	font-weight: 300;
}

#CompanyNameInfo {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 25px;
	border-bottom: 1px dotted rgb(102, 102, 102);
}

#displayedCompanyName {
	font-weight: 300;
}

#phoneValue {
	position: relative;
	top: 5px;
}

.AdressFields {
	text-align: center;
	width: 350px;
	min-width: 180px;
	min-height: 20px;
	padding: 0px 7px;
	background-color: rgb(85, 85, 85);
	border-radius: 5px;
}

.AdressFieldsPlaceHolder {
	color: var(--SecondFOntColor);
}

.WebsiteFields {
	text-align: center;
	width: 250px;
	min-width: 180px;
	min-height: 20px;
	padding: 0px 7px;
	background-color: rgb(85, 85, 85);
	border-radius: 5px;
	overflow: hidden;
}

.UserOffersHeader {
	text-align: center;
	height: 35px;
	width: auto;
	padding: 0px 20px;
	background-color: rgb(75, 75, 75);
	border-radius: 30px;
	margin: 20px 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--MainFontColor);
	font-weight: 100;
	font-size: 20px;
	cursor: pointer;
	user-select: none;
	border: 1px solid rgb(92, 92, 92);
}

.UserOffersHeader:hover {
	scale: 1.05;
	font-weight: 300;
}

.UserOffersHeaderActive {
	text-align: center;
	height: 35px;
	width: auto;
	padding: 0px 20px;
	background-color: rgb(75, 75, 75);
	border-radius: 30px;
	margin: 0px 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--MainFontColor);
	font-weight: 100;
	font-size: 20px;
	border: 1px solid gray;
	cursor: pointer;
	user-select: none;
}

.UserOffersHeaderActive:hover {
	scale: 1.05;
	font-weight: 300;
}

#CompanyNameToShort {
	margin-left: auto;
	margin-right: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgb(196, 81, 81);
	border-radius: 10px;
	height: 20px;
	width: 200px;
}

#companyName {
	font-size: 18px;
	font-weight: 300;
	color: rgb(156, 156, 156);
}

#CompanyNameInput {
	width: 250px;
}

#PhoneNumToShort {
	margin-left: auto;
	margin-right: auto;
	margin: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgb(196, 81, 81);
	border-radius: 10px;
	height: 20px;
	width: 200px;
}

#DescriptionToShort {
	margin-left: auto;
	margin-right: auto;
	margin: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgb(196, 81, 81);
	border-radius: 10px;
	height: 20px;
	width: 200px;
}

#DescriptionInput {
	width: 500px;
	min-width: 150px;
	word-wrap: break-word;
	word-break: break-all;
	height: 80px;
}

#ShowWholeDescription {
	position: relative;
	left: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 120px;
	height: 35px;
	background-color: rgb(196, 81, 81);
	border-radius: 10px;
	margin: 0px 5px;
}

#descriptionFieldAndBTN {
	display: flex;
	align-items: center;
	justify-content: center;
}

#ShowWholeDescription {
	cursor: pointer;
}

#AdressDiv {
	display: flex;
	align-items: center;
	justify-content: center;
}

#WorkersOffersButton {
	background: linear-gradient(#8f8f8f, #6e6e6ef6);
}

#UserDataDiv {
	height: auto;
	width: 850px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 15px 0px;
	font-weight: 300;
}

#btnsMainDiv {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: 10%;
	height: 100%;
}

.TitleAndValueContainer {
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin: 3px 0px;
}

.Titles {
	position: relative;
	top: 15px;
	font-size: 18px;
	color: var(--SecondFOntColor);
	font-weight: 300;
	margin: 0px;
	user-select: none;
}

#fullDescriptionArrow {
	position: relative;
	top: 25px;
	width: 30px;
	height: 30px;
	margin: 0px 5px;
	cursor: pointer;
}

.EditBtns {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 165px;
	height: 25px;
	background-color: var(--MainButtonColor);
	border-radius: 15px;
	font-size: 14px;
	font-weight: 300;
	border-radius: 20px;
	margin: 15px;
	padding: 0px 12px;
	cursor: pointer;
}

.EditUserDataIcon {
	width: 16px;
	height: 16px;
	margin: 0px 0px;
}

.EditBtns:hover {
	transform: scale(1.05);
}

#akceptInfoMessage {
	font-size: 20px;
	font-weight: 100;
}

.UserPanelNoOfferInfoAndAddButton {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 30px;
}

.UserLogoUserPanel {
	min-width: 250px;
	width: 250px;
	min-height: 250px;
	height: 250px;
	border-radius: 20px;
	margin: 10px;
	cursor: pointer;
}

.UserPanelAddButtons {
	background-color: var(--MainButtonColor);
	border-radius: 30px;
	width: 190px;
	padding: 0px 20px;
	height: 30px;
	margin: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--MainFontColor);
	font-weight: 100;
	cursor: pointer;
}

#NotificationsButtonMainContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-weight: 100;
}

.NotificationsSwitch {
	cursor: pointer;
}

.NotificationsSwitch:hover {
	scale: 1.05;
	font-weight: 300;
}

.UserNotificationsAcceptLogo {
	margin: 0px 10px;
	color: rgb(90, 193, 90);
}

.UserNotificationsDeniedLogo {
	margin: 0px 10px;
	color: rgb(248, 94, 77);
}

.EndedUserOffersMainContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	min-width: 800px;
}

.EndedOffersInfoHeader {
	text-align: center;
	font-size: 20px;
	font-weight: 300;
	margin:25px 0px;
}

@media screen and (max-width: 500px) {
	/*MOBILE SECTION*/

	#LoggedUserMainDiv {
		width: 100%;
		height: auto;
		padding-top: 30px;
	}

	#AwaitingOffersMainDiv {
		min-height: 280px;
		width: 90vw;
	}

	.EndedUserOffersMainContainer {
		min-width: 90vw;
	}

	#descriptionFieldAndBTN {
		width: 100%;
	}

	.Titles {
		position: relative;
		top: 15px;
	}

	.TitleAndValueContainer {
		width: 90%;
		height: auto;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		cursor: pointer;
		margin: 1px 0px;
	}

	#AwaitingOffersMainDiv::-webkit-scrollbar {
		visibility: hidden;
		background-color: var(--MainBackGroundColor);
		width: 0px;
		border-radius: 5px;
	}

	.Titles {
		position: relative;
		left: -9px;
		font-size: 16px;
		color: var(--SecondFOntColor);
		font-weight: 300;
	}

	#UserOffersDiv {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-direction: column;
		width: 100vw;
		height: 650px;
		overflow: hidden;
	}

	#UserOffersTypeHeader {
	width: 95%;
	}

	.WebsiteFields {
		position: relative;
		top: 18px;
		width: 145px;
	}

	#phoneValue {
		position: relative;
		top: 0px;
	}

	.VerifyInfoText {
		font-size: 20px;
	}

	.AdressFields {
		width: 90%;
	}

	#UserDataDiv {
		/*DANE UŻYTKOWNIKA*/
		min-height: 500px;
		width: 100vw;
		padding-left: 5px;
	}

	#btnsMainDiv {
		position: relative;
		left: -12px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		width: 10%;
		height: 100%;
	}

	#fullDescriptionArrow {
		position: relative;
		left: -20px;
		top: 25px;
		width: 30px;
		height: 30px;
		margin: 0px 5px;
		cursor: pointer;
	}

	.VerifyInfoText2 {
		font-family: var(--MainFontType);
		font-weight: 200;
		color: var(--MainFontColor);
		font-size: 15px;
	}

	#AdressValues {
		width: 100%;
		margin: 5px 0px;
	}

	#UserProfileInfoDiv {
		/*NAZWA I MAIL*/
		position: relative;
		top: -35px;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 10px;
		width: 90%;
		min-height: 35px;
	}

	#MyOffersDisplay::-webkit-scrollbar {
		width: 0px;
	}

	#MyOffersDisplay::-webkit-scrollbar-thumb {
		background-color: rgb(92, 92, 92);
		border-radius: 5px;
		border: none;
	}

	#CompanyNameInfo {
		width: 90%;
		flex-direction: column;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 20px;
		border-bottom: 1px dotted rgb(102, 102, 102);
	}

	#ConfirmVeryfication {
		background-color: rgb(196, 81, 81);
		border-radius: 30px;
		padding: 0px 15px;
		font-size: 15px;
		font-family: var(--MainFontType);
		font-weight: 200;
		margin: 15px;
	}

	#VerifyInfoText {
		width: auto;
		font-size: 16px;
		text-align: center;
	}

	.EditBtns {
		font-size: 15px;
	}

	.UserOffersHeaderActive {
		height: 30px;
		width: 145px;
		font-size: 14px;
		margin: 7px;
		padding: 4px;
	}

	.UserOffersHeader {
		height: 30px;
		width: 145px;
		font-size: 14px;
		margin: 7px;
		padding: 4px;
	}

	.EndedOffersInfoHeader {
		text-align: center;
		font-size: 20px;
		font-weight: 300;
		margin-bottom: 10px;
	}

}

@media screen and (min-width: 1900px) {
	/*BIG SCREEN SECTION*/
}
