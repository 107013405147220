#PaymentButton {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 120px;
	height: 30px;
	background-color: rgb(75, 168, 98);
	border-radius: 25px;
	font-size: 18px;
	border: 1px solid var(--MainFontColor);
	cursor: pointer;
	color: var(--MainFontColor);
	margin: 10px;
}

#PaymentButtonHolded {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 120px;
	height: 30px;
	background-color: rgb(75, 168, 98);
	border-radius: 25px;
	font-size: 18px;
	border: 1px solid var(--MainFontColor);
	cursor: pointer;
	color: var(--MainFontColor);
	margin: 10px;
	animation-name: example;
	animation-duration: 4s;
	animation-iteration-count: infinite;
}

@keyframes example {
	0%   {background-color:rgb(75, 168, 98); left:0px; top:0px;}
	50%  {background-color:rgb(2, 72, 16); left:0px; top:0px;}
	100% {background-color:rgb(75, 168, 98); left:0px; top:0px;}
  }


