#AddOfferMainDiv {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	height: auto;
	width: 100%;
	background-color: var(--MainBackGroundColor);
	color: var(--MainFontColor);
	font-family: var(--MainFontType);
}

#SamplesChooseDiv {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 20px;
	height: auto;
}


#AddOfferSalaryDIV {
	display: flex;
	flex-direction: row;
	margin: 2px;
	justify-content: center;
}

#SalaryTypeContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.InputHeaders {
	font-weight: 300;
}

#roleSelect {
	margin: 5px;
}

#ExperienceSelect {
	margin: 5px;
}

.SalaryInputs {
	text-align: center;
	width: 50px;
	height: 20px;
	margin: 5px;
	border-radius: 15px;
	color: var(--MainFontColor);
	background-color: rgb(105, 105, 105);
	border: 1px solid rgb(148, 148, 148);
	font-family: "Roboto", sans-serif;
	font-weight: 300;
}

.SalaryInputs::placeholder {
	color: var(--MainFontColor);
}

#SalaryInputs::-ms-input-placeholder {
	color: var(--MainFontColor);
}

#salaryHint {
	align-self: center;
	font-weight: 100;
	background-color: rgb(211, 80, 80);
	border-radius: 15px;
	padding: 1px 8px;
	color: rgb(255, 255, 255);
}

.SalaryInputsWarn {
	text-align: center;
	transform: translateY(+20%);
	width: 50px;
	height: 30px;
	margin: 5px;
	border-radius: 5px;
	background-color: rgb(252, 127, 127);
	font-family: "Roboto", sans-serif;
	font-weight: 300;
}

.DisplayTypeHeader {
	font-weight: 300;
}

#CurrencySelect {
	width: 65px;
	height: 30px;
}

#TitleInput::placeholder {
	margin: 5px;
	color: var(--MainFontColor);
	opacity: 1;
}

#TitleInput::-ms-input-placeholder {
	color: var(--MainFontColor);
}


#DescriptionInput {
	width: auto;
	height: 200px;
	border-radius: 15px;
	text-align: left;
	padding: 4px;
	cursor: default;
}

#DescriptionInput::placeholder {
	color: var(--MainFontColor);
}

#DescriptionInput::-ms-input-placeholder {
	color: var(--MainFontColor);
}

#AddOfferButton {
	width: 50%;
	align-self: center;
	margin: 5px;
	border-radius: 5px;
	background-color: rgb(226, 225, 225);
	font-family: "Roboto", sans-serif;
	font-size: 15px;
	font-weight: 900;
}

#AddOfferButton:hover {
	background-color: rgb(113, 155, 30);
	cursor: pointer;
}

#SalaryInfoDIV {
	width: 90%;
	margin-left: auto;
	margin-right: auto;
	font-size: 14px;
	background-color: var(--SecondFOntColor);
	text-align: center;
	border-radius: 15px;
	color: rgb(240, 205, 11);
}

.ChooseOptionBTN {
	position: relative;
	width: 80px;
	height: 45px;
	top: 140px;
	left: -95px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgb(196, 81, 81);
	border-radius: 10px;
	font-family: var(--MainFontType);
	font-weight: 100;
}

.ChooseOptionbtnText {
	font-weight: 200;
}

.ChooseOptionBTN:hover {
	cursor: pointer;
}

#StripeBtn {
	opacity: 0;
}

#fakePayBtn {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	top: -50%;
	cursor: pointer;
	pointer-events: none;
	border: 1px solid wheat;
	border-radius: 5px;
	background-color: rgb(18, 122, 18);
	height: 35px;
	width: 70px;
}

.OfferDisplay {
	height: 100px;
}

#ChoosedOfferDetails {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: rgb(247, 227, 191);
}


#OfferMini {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

#choosedOptionTitle {
	font-weight: 300;
}

.choosedOfferDescription {
	font-weight: 300;
}

#FreeOfferInfo {
	color: orange;
}

#TitleLengthInfo {
	align-self: center;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 25px;
	padding: 1px 9px;
	border-radius: 20px;
	background-color: rgb(49, 49, 49);
	color: rgb(212, 160, 63);
	font-weight: 100;
}

#statuteAcceptDiv {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

#SalariesDiv {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin: 15px;
}

#currenciesDiv {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin: 15px;
}

.SimpleOfferTypeContainer {
	height: 320px;
	cursor: pointer;
}

#statutePopUpLink {
	text-decoration: underline;
	cursor: pointer;
}

@media screen and (max-width: 450px) {
	/*MOBILE SECTION*/

	#AddOfferMainDiv {
		top: 5px;
	}

	#SamplesChooseDiv {
		position: static;
		top: 130px;
	}

	.OfferDisplay {
		height: 105px;
	}

	.OfferDisplayPromoted {
		height: 90px;
	}

	#SalariesDiv {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		margin: 15px;
	}

	#SalaryHeader {
		font-size: 19px;
	}

	#AddOfferSalaryDIV {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		margin: 2px;
		justify-content: center;
	}

	#DescriptionInput {
		width: auto;
		height: 200px;
		border-radius: 15px;
		text-align: left;
		padding: 4px;
	}

	.ChooseOptionBTN {
		position: relative;
		width: 85px;
		height: 35px;
		top: 53px;
		left: 155px;
		border-radius: 30px;
	}

	.SimpleOfferTypeContainer {
		height: 380px;
	}

	.SalaryInputs {
		width: 45px;
	}

	.Title {
		font-size: 15px;
	}

	#CurrencySelect {
		height: 26px;
		width: 70px;
	}

	.PhoneNumAddedToOffer {
		width: 90vw;
		text-align: center;
		font-size: 18px;
		margin: 10px;
		cursor: pointer;
	}

	.PhoneNumAddedToOffer .PhoneToolTip {
		visibility: hidden;
		position: absolute;
		bottom: 0px;
		height: 0px;
		width: 0px;
	}

}
