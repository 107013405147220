.filteredCountryTipButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 30px;
	width: 160px;
	border-radius: 15px;
    margin: 5px;
    padding: 0px 8px;
	background-color: rgb(116, 116, 116);
	border: 1px solid rgb(227, 227, 227);
	color: var(--MainFontColor);
	font-weight: 100;
	font-family: var(--MainFontType);
	cursor: pointer;
}

.filteredCountryNotFoundTipButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 30px;
	width: 180px;
	border-radius: 15px;
    margin: 5px;
    padding: 0px 8px;
	background-color: rgb(116, 116, 116);
	border: 1px solid rgb(227, 227, 227);
	color: var(--MainFontColor);
	font-weight: 100;
	font-family: var(--MainFontType);
	cursor: pointer;
}

.filteredCountryTipIcon {
    color: var(--MainFontColor);
    margin: 0px 3px;
}

@media screen and (max-width: 500px) {
	/*MOBILE SECTION*/
    .filteredCountryTipButton {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 20px;
        width: 155px;
        border-radius: 15px;
        margin: 3px;
        padding: 0px 8px;
        background-color: rgb(116, 116, 116);
        border: 1px solid rgb(227, 227, 227);
        color: var(--MainFontColor);
        font-weight: 100;
        font-family: var(--MainFontType);
        cursor: pointer;
    }
}