.PopularCountryButton {
	height: 30px;
	width: 80px;
	border-radius: 15px;
    margin: 5px;
	background-color: rgb(100, 100, 100);
	border: 1px solid rgb(148, 148, 148);
	color: var(--MainFontColor);
	font-weight: 100;
	font-family: var(--MainFontType);
	cursor: pointer;
}

@media screen and (max-width: 500px) {
	/*MOBILE SECTION*/

    .PopularCountryButton {
        height: 25px;
        width: 80px;
        border-radius: 15px;
        margin: 5px;
        background-color: rgb(100, 100, 100);
        border: 1px solid rgb(148, 148, 148);
        color: var(--MainFontColor);
        font-weight: 100;
        font-family: var(--MainFontType);
        cursor: pointer;
    }

}
