#StatuteDisplayMainContaner {
	width: 100%;
	height: 90vh;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	overflow-y: scroll;
}

#StatuteDisplayMainContaner::-webkit-scrollbar {
	visibility: hidden;
	border-radius: 5px;
}

#StatuteDisplayMainContaner::-webkit-scrollbar-thumb {
	background-color: rgb(65, 64, 64);
	border-radius: 5px;
	border: 1px solid gray;
}