.MobileMapCloseButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    color: var(--MainFontColor);
    font-weight: 100;
    width: 150px;
    padding: 3px 10px;
    border-radius: 20px;
    background-color: rgb(114, 114, 114);
    border: none;
    margin: 5px;
    box-shadow: rgba(46, 46, 46, 0.363) 3px 2px 2px;
}

.MapCloseBtnText {
    font-size: 15px;
    margin: 0px;
}

.MapCloseBtnIcon {
    font-weight: 100;
    width: 23px;
    height: 23px;
}