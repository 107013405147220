#LoginPageMainDiv {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100vh;
    background: linear-gradient(-33deg, #503f3a, #050505, #4d4d4d, #555555);
    background-size: 400% 400%;
    animation: gradient 25s ease infinite;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}


#Form {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background-color: rgb(59, 59, 59);
	border-radius: 40px;
	width: 600px;
	height: 550px;
	min-height: 400px;
	font-family: var(--MainFontType);
	box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.63);
	border: 1px solid gray;
}

#FormReg {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgb(59, 59, 59);
	border-radius: 40px;
	width: 30vw;
	height: 60vh;
	border: 1px solid gray;
}

#LoginScreen {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	font-family: var(--MainFontType);
}

#BlockedLoginScreen {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	font-family: var(--MainFontType);
	background-color: brown;
}

#LoginLogo {
	font-family: var(--SecondFontType);
	color: var(--MainFontColor);
	cursor: pointer;
	font-size: 40px;
	text-shadow: 2px 2px 5px rgb(0, 0, 0);
	margin: 5px;
}

.LoginInputs {
	text-align: center;
	border-radius: 15px;
	margin: 5px;
	border: none;
	background-color: rgb(2, 2, 2);
	width: 300px;
	min-height: 25px;
	color: var(--MainFontColor);
	box-shadow: 2px 2px 5px rgb(0, 0, 0);
	visibility: visible;
}

.BlockedInputs {
	visibility: hidden;
}

.BlockedInput {
	text-align: center;
	border-radius: 15px;
	margin: 5px;
	border: none;
	background-color: rgb(248, 248, 248);
	width: 110%;
	height: 25px;
	color: var(--MainFontColor);
	box-shadow: 2px 2px 5px rgb(0, 0, 0);
}

#LoginLinks {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

#termsAndConditions {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.LoginTermsLink {
	color: black;
	width: 200px;
	text-align: center;
	color: gray;
	margin: 6px 0px;
}

#LoginBtn {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--MainButtonColor);
	width: 120px;
	height: 30px;
	border-radius: 15px;
	margin: 8px;
	color: black;
	cursor: pointer;
	font-weight: 300;
}

.Link {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--SecondButtonColor);
	width: auto;
	padding: 0px 10px;
	height: 30px;
	border-radius: 15px;
	margin: 10px;
	color: black;
	cursor: pointer;
	font-weight: 300;
}

.PolicyLinkText:hover {
   text-decoration: underline;
}

.SignPageSubTitle {
	color: rgb(107, 107, 107);
	margin: 5px;
}

.passwordVisibilitySwitchIcon {
	position: absolute;
	margin-left: 245px;
	width: 30px;
	height: 30px;
	color: rgb(134, 134, 134);
	cursor: pointer;
}

.PasswordAndVisibilityIconDiv {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
}

.registerOwnerInfo {
	width: 90%;
	font-weight: 300;
	font-size: 15px;
	color: rgb(104, 104, 104);
	text-align: center;
	margin: 5px 0px;
}

#RegisterScreen {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	font-family: var(--MainFontType);
	min-height: 40vh;
	height: 40vh;
}

#PasswResetMainContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	font-family: var(--MainFontType);
	min-height: 40vh;
	height: 40vh;
}

#passWarning {
	position: relative;
	bottom: 10px;
	font-size: 17px;
	color: var(--MainFontColor);
	height: 1px;
	font-weight: 300;
}

#ErrorMessages {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 15px;
	position: relative;
	color: var(--MainFontColor);
	height: 10px;
	width: auto;
	padding: 10px;
	font-weight: 300;
	background-color: #e76d6d;
	border-radius: 20px;
}

@media screen and (max-width: 500px) {
	/*MOBILE SECTION*/

	#Form {
		width: 100%;
		height: 70%;
		min-height: 480px;
	}

	#FormReg {
		width: 90vw;
		height: 70vh;
		min-height: 480px;
	}

	.LoginInputs {
		width: 270px;
	}
	

	#LoginLogo {
		font-size: 35px;
	}

	.registerOwnerInfo {
		width: 290px;
		font-weight: 300;
		font-size: 11px;
		color: rgb(104, 104, 104);
		text-align: center;
		margin: 5px 0px;
	}

}
