#CookiesDiv {
	width: 100%;
}

#MainCookiesDiv {
	position: fixed;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	bottom: 0px;
	width: 100%;
	min-width: 1100px;
	height: 150px;
	background-color: rgba(99, 99, 99, 0.274);
	z-index: 2;
	backdrop-filter: blur(8px);
	font-family: var(--MainFontType);
	color: var(--MainFontColor);
	font-weight: 300;
	border-top: 1px solid rgb(110, 110, 110);
}

#hideCookiesBtn {
	height: 30px;
	width: 100px;
	background-color: var(--MainButtonColor);
	font-family: var(--MainFontType);
	color: var(--MainFontColor);
	border-radius: 20px;
	border: none;
	cursor: pointer;
	font-size: 18px;
	font-weight: 300;
}

#CookiesPageLink {
	font-weight: 400;
	color: var(--MainFontColor);
	margin: 15px;
}

#CookiesPageLink:hover {
	text-decoration: underline;
}

@media screen and (max-width: 500px) {
	/*MOBILE SECTION*/

	#MainCookiesDiv {
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		height: 200px;
		min-width: 200px;
	}

	#hideCookiesBtn {
		height: 30px;
		width: 100px;
		background-color: var(--MainButtonColor);
		font-family: var(--MainFontType);
		color: var(--MainFontColor);
		border-radius: 20px;
		border: none;
		cursor: pointer;
		font-size: 16px;
		font-weight: 300;
	}
}
