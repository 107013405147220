.JobStatusMessage {
    font-weight: 100;
    margin: 5px;
}

.JobStatusConfirmationButtons {
    margin-top: 18px;
    display: flex;
    flex-direction: row;
}

.JobSearchMessageBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}