.FilledJobFilter {
	width: 112px;
	height: 23px;
	display: flex;
	align-items: center;
	justify-content: space-around;
	flex-direction: row;
	color: var(--MainFontColor);
	border-radius: 8px;
	font-family: var(--MainFontType);
	margin: 0px 5px;
	padding: 2px 5px;
	color: var(--MainFontColor);
	font-size: 16px;
	font-weight: 300;
	background-color: rgb(104, 104, 104);
	border: 1px solid var(--MainFontColor);
	box-shadow: 2px 2px 5px rgba(61, 61, 61, 0.829);
}

.JobFilter {
	width: 112px;
	height: 23px;
	display: flex;
	align-items: center;
	justify-content: space-around;
	flex-direction: row;
	border: 1px solid rgb(92, 92, 92);
	color: var(--MainFontColor);
	border-radius: 8px;
	font-family: var(--MainFontType);
	margin: 0px 5px;
	padding: 2px 5px;
	background-color: var(--MainBackGroundColor);
	color: var(--MainFontColor);
	font-size: 16px;
	font-weight: 300;
	box-shadow: 2px 2px 5px rgba(61, 61, 61, 0.829);
}

.JobFilter:hover {
	cursor: pointer;
	background-color: rgb(77, 77, 77);
}

.RoleButtonIcon {
	width: 20px;
	height: 20px;
}

@media screen and (max-width: 500px) {
	/*MOBILE SECTION*/
	.JobFilter {
		font-weight: 100;
		padding: 3px 20px;
		min-width: 130px;
		margin: 0px 2px;
	}

	.FilledJobFilter {
		font-weight: 100;
		padding: 3px 20px;
		min-width: 130px;
		margin: 0px 2px;
	}

	.RoleButtonIcon {
		min-width: 20px;
		min-height: 20px;
	}
	
}