
.OfferDisplay {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	border-radius: 5px;
	margin: 10px;
	max-width: 600px;
	min-width: 450px;
	height: 75px;
	font-family: var(--MainFontType);
	font-size: 19px;
	cursor: pointer;
	color: var(--MainFontColor);
	border: 1px solid rgb(92, 92, 92);
	padding: 0px 5px;
	box-shadow: 2px 2px 5px rgba(61, 61, 61, 0.829);
}

.OfferDisplay:hover {
	transform: scale(1.01);
	animation-name: shadowTransition;
	animation-duration: 0.5s;
	box-shadow: 3px 2px 3px rgba(128, 128, 128, 0.192);
}

@keyframes shadowTransition {
	0%   { box-shadow: 2px 2px 5px rgba(61, 61, 61, 0.829);}
	100% { box-shadow: 3px 2px 3px rgba(128, 128, 128, 0.192);}
  }

.OfferDisplayUser {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
	margin: 10px;
	width: 600px;
	height: 75px;
	font-family: var(--MainFontType);
	font-size: 19px;
	cursor: pointer;
	color: var(--MainFontColor);
	border: 1px solid rgb(92, 92, 92);
}

.OfferDisplayUser:hover {
	transform: scale(1.01);
}

.OfferDisplayPromoted {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	border: 1px solid var(--MainFontColor);
	background-color: rgb(68, 68, 68);
	border-radius: 5px;
	margin: 10px;
	max-width: 600px;
	min-width: 450px;
	height: 100px;
	min-height: 100px;
	font-family: var(--MainFontType);
	font-size: 19px;
	cursor: pointer;
	color: var(--MainFontColor);
	padding: 0px 5px;
	box-shadow: 1px 1px 8px rgba(223, 152, 70, 0.5);
}

.OfferDisplayPromoted:hover {
	transform: scale(1.01);
}

.OfferDisplayPromotedUser {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	border: 1px solid var(--MainFontColor);
	background-color: rgb(68, 68, 68);
	border-radius: 5px;
	margin: 10px;
	width: 600px;
	height: 75px;
	font-family: var(--MainFontType);
	font-size: 19px;
	cursor: pointer;
	color: var(--MainFontColor);
	box-shadow: 1px 2px 10px rgba(223, 152, 70, 0.87);
}

.OfferDisplayPromotedUser:hover {
	transform: scale(1.01);
}

.LogoDiv {
	display: flex;
	align-items: center;
	justify-content: center;
}

.RoleLogo {
	opacity: 0.9;
	width: 55px;
	height: 55px;
	margin: 5px 10px;
}

.OfferDetails {
	width: 70%;
	height: 80px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	flex-grow: 2;
}

.SingleOfferTitle {
	position: relative;
	width: 500px;
	height: 20px;
	font-size: 17px;
	font-weight: 300;
	overflow: visible;
	margin: 0px;
}

.ClockLogo {
	margin: 0px 3px;
}

.Factory {
	position: relative;
	bottom: 1px;
	margin: 3px;
}

.CompanyDiv {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	height: 30px;
	width: 99%;
	min-width: 380px;
	font-size: 15px;
	color: var(--SecondFOntColor);
}

.SingleOfferCompanyNameAndLogo {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-weight: 300;
}

.addDate {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	flex-grow: 2;
	background-color: var(--SecondFOntColor);
	border-radius: 10px;
	height: 20px;
	width: 95px;
	max-width: 95px;
	margin-left: 5px;
	color: var(--MainBackGroundColor);
	font-size: 13px;
	padding: 0px 7px;
}

.Details2 {
	height: 25px;
	max-width: 99%;
	width: 99%;
	min-width: 85%;
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.Country {
	font-size: 15px;
	color: var(--SecondFOntColor);
}

.PreviewSalary {
	font-size: 14px;
	color: rgb(73, 156, 95);
}

.SalaryValueAndTypeContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.DisplayViews {
	font-size: 13px;
	background-color: rgb(85, 85, 85);
	border-radius: 10px;
	margin: 2px;
	padding: 0px 5px;
	font-weight: 300;
}

.UserLogoSingleOffer {
	width: 55px;
	height: 55px;
	margin: 5px 10px;
	border-radius: 5px;
}

.SingleOfferLocationContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-bottom: 30px;
}

.SingleOfferLocationLogo {
	margin: 0px;
	color: var(--SecondFOntColor);
}

@media screen and (max-width: 500px) {
	/*MOBILE SECTION*/

	.SingleOfferMainDiv {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		margin: 5px 0px;
		user-select: none;
	}

	.OfferDisplay {
		min-height: 113px;
		width: 96vw;
		margin: 2px 0px;
		min-width: 300px;
		box-shadow: 2px 2px 5px rgba(61, 61, 61, 0.829);
	}

	.OfferDisplayPromoted {
		width: 96vw;
		margin: 2px 0px;
		min-height: 100px;
		min-width: 280px;
		box-shadow: 1px 1px 8px rgba(223, 152, 70, 0.5);
	}

	.OfferDisplayPromotedUser {
		position: relative;
		width: 96vw;
		margin: 2px;
		margin-top: 30px;
		min-height: 100px;
	}

	.CompanyDiv {
		position: relative;
		top: 14px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		height: 30px;
		width: 265px;
		min-width: 265px;
		font-size: 15px;
		color: var(--SecondFOntColor);
	}

	.OfferDetails {
		position: relative;
		width: 70%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
	}

	.SingleOfferTitle {
		top: -10px;
		position: relative;
		width: 270px;
		height: 20px;
		font-size: 15px;
		font-weight: 300;
		overflow: visible;
		margin: 0px;
		text-align: left;
	}

	.RoleLogo {
		width: 65px;
		height: 65px;
		margin: 5px 8px;
	}

	.UserLogoSingleOffer {
		width: 65px;
		height: 65px;
		margin: 5px 8px;
		border-radius: 5px;
	}

	.addDate {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		background-color: var(--SecondFOntColor);
		border-radius: 10px;
		height: 15px;
		max-width: 76px;
		margin-left: 5px;
		color: var(--MainBackGroundColor);
		font-size: 10px;
		padding: 0px 7px;
	}

	.PreviewSalary {
		font-size: 12px;
	}

	.Country {
		font-size: 12px;
	}

	.DisplayViews {
		font-size: 11px;
		font-weight: 100;
	}

	.Details2 {
		position: relative;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		height: 25px;
		width: 260px;
		min-width: 260px;
		top: 5px;
	}

	.SingleOfferCompanyName {
		font-size: 12.5px;
	}

	.SingleOfferLocationContainer {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		margin-bottom: 0px;
	}

	.SingleOfferLocationLogo {
		width: 13px;
		height: 13px;
	}
}
