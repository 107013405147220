#TopBarMainDiv {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: var(--MainBackGroundColor);
	width: 100%;
	height: 40px;
	font-family: var(--MainFontType);
	margin: 0px;
}
.LogoAndButtons {
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: space-evenly;
	color: var(--MainFontColor);
	margin: 0px 12px;
	width: 100%;
}

.MainLogoText {
	font-size: 35px;
	margin: 0px 6px;
	text-decoration: none;
	font-family: var(--SecondFontType);
	color: var(--MainFontColor);
}

.LogoAndSubLogo {
	width: 100%;
	height: 65px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	flex-grow: 2;
	margin: 0px;
}

#SubLogoText {
	align-self: flex-end;
	font-weight: 300;
	font-family: var(--MainFontType);
	font-size: 14px;
	color: var(--SecondFOntColor);
}

.MainButtons {
	/*DivForText*/
	background-color: var(--MainButtonColor);
	border-radius: 30px;
	width: 150px;
	padding: 0px 20px;
	height: 30px;
	margin: 10px;
	display: flex;
	align-items: center;
	justify-content: space-around;
	color: var(--MainFontColor);
	font-weight: 100;
	cursor: pointer;
}

a:-webkit-any-link {
	text-decoration: none;
	cursor: pointer;
}

.loggedUserAddButtonIcon {
	width: 20px;
	height: 20px;
}


@media screen and (max-width: 500px) {
	/*MOBILE SECTION*/

	#TopBarMainDiv {
		position: inherit;
		height: 70px;
		min-height: 70px;
		min-width: 320px;
		width: 100%;
		padding: 1px;
	}

	.MainLogo {
		width: 40px;
		overflow: visible;
		margin: 2px;
	}

	.MainLogoText {
		font-size: 21px;
		margin: 0px;
	}

	.LogoAndSubLogo {
		height: auto;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		margin-left: 5px;
	}

	.MainButtons {
		width: 68px;
		padding: 0px 6px;
		margin: 0px 3px;
		height: 22px;
		font-size: 11px;
		font-weight: 300;
		box-shadow: 2px 2px 2px rgba(48, 48, 48, 0.432);
	}

	#SubLogoText {
		align-self: flex-start;
		font-size: 10px;
		color: var(--SecondFOntColor);
		margin: 0px;
		white-space: nowrap;
		position: absolute;
		top: 50px;
		text-shadow: 1px 2px 6px rgba(0, 0, 0, 0.678);
	}

	.ButtonText {
		display: none;
		font-size: 11px;
	}

	#loggedUserAddButtonText {
	  width: 0px;
	  height: 0px;
	  display: none;
	}

	.loggedUserAddButtonIcon {
		width: 18px;
		height: 18px;
	}
}
