.choosedCityContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 30px;
	width: auto;
	border-radius: 15px;
    margin: 5px;
    padding: 0px 8px;
	background-color: rgb(116, 116, 116);
	border: 1px solid rgb(227, 227, 227);
	color: var(--MainFontColor);
	font-weight: 100;
	font-family: var(--MainFontType);
	cursor: pointer;
}