.ChoosedCountryNameContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: 23px;
	width: auto;
	margin: 5px 10px;
	background-color: gray;
	border-radius: 20px;
	padding: 0px 7px;
	z-index: 1;
}

.ChoosedCountryName {
	font-weight: 100;
	color: var(--MainFontColor);
	margin: 6px;
}

.ChoosedCountryCloseBtn {
	color: var(--MainFontColor);
}

@media screen and (max-width: 500px) {
	/*MOBILE SECTION*/

	.ChoosedCountryName { 
		font-weight: 100;
		color: var(--MainFontColor);
		margin: 6px;
		width: auto;
		white-space: nowrap;
	}
}
