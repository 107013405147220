

.statsItems {
    color: rgb(165, 165, 165);
    margin: 25px 0px;
    font-weight: 300;
}

.statsItemsHighLighted {
    text-align: center;
    color: rgb(165, 165, 165);
    margin: 25px 0px;
    font-weight: 500;
    background-color: rgb(97, 96, 96);
    border-radius: 10px;
}

.statsItemsSmall {
    color: rgb(165, 165, 165);
    margin: 5px 0px;
    font-size: 11px;
    text-align: center;
}

.sectionContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 650px;
    border: 1px solid gray;
    border-radius: 10px;
    padding: 10px;
    margin: 5px;
}

#statsMainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-family: var(--MainFontType);
    width: 100vw;
    min-height: 100vh;
}

@media screen and (max-width: 500px) {
	/*MOBILE SECTION*/
    .sectionContainer {
        width: 290px;
    }
}