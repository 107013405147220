#FooterMainDiv {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 60%;
	height: 22px;
	background-color: var(--MainBackGroundColor);
	padding: 0px;
}

.FooterLink {
	font-family: var(--MainFontType);
	text-decoration: none;
	color: gray;
	margin: 0px 15px;
}

.FooterLink:hover {
	border-bottom: 1px solid rgb(82, 82, 82);
}

@media screen and (max-width: 500px) {
	/*MOBILE SECTION*/

	#FooterMainDiv {
		width: 100%;
		height: 32px;
		border-top: 1px dotted gray;
		padding: 2px;
	}

	.FooterLink {
		height: auto;
		width: 90px;
		font-family: var(--MainFontType);
		text-decoration: none;
		color: gray;
		margin: 0px 10px;
		font-size: 11px;
		text-align: center;
	}
}
