
#AddCompanyLogoMainContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: auto;
}

.LogoSaveConfirm {
    color: var(--mainFontColor);
	font-weight: 300;
	padding: 3px 15px;
	border-radius: 15px;
	background-color: rgb(112, 161, 112);
}

.FileSizeWarning {
	text-align: center;
	font-weight: 100;
	padding: 3px 10px;
	border-radius: 10px;
	background-color: rgb(212, 97, 69);
}

.LogoUploaderForm {
	height: auto;
}


#file-upload {
	height: 280px;
	width: 300px;
	visibility: visible;
	opacity: 0;
	border: 1px solid red;
	cursor: pointer;
	margin: 30px 0px;
}

.chooseFileInfo {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.CompanyLogoPreviewContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.chooseFileInfoText {
	font-size: 20px;
	font-weight: 100;
	margin: 5px 0px;
}

.LogoUploaderChooseFileIcon {
	width: 60px;
    height: 60px;
	margin: 10px;
}

.CompanyLogoPreview {
	width: 180px;
	height: 180px;
	border-radius: 20px;
}

.fileUploadInputPlaceHolder {
	height: 260px;
	width: 300px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: absolute;
	bottom: 120px;
	pointer-events: none;
	margin: 0px;
	border: 1px dotted var(--MainFontColor);
	border-radius: 20px;
}