#ContactMainContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 97vw;
    height: auto;
    padding: 50px 0px;
}

#FormMainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5px;
}

#ContactContactValuesContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0px;
}

#ContactLogo {
    font-family: var(--SecondFontType);
    color: var(--MainFontColor);
    font-weight: 100;
    font-size: 50px;
}

.ContactHeader {
    font-family: var(--MainFontType);
    color: var(--MainFontColor);
    font-weight: 100;
    font-size: 20px;
}

.ContactValue {
    font-family: var(--MainFontType);
    color: rgb(129, 129, 129);
    font-weight: 100;
    font-size: 24px;
}

.InputHeaders {
    font-family: var(--MainFontType);
    color: rgb(129, 129, 129);
    align-self: flex-start;
}


.FeedBackContactInput {
    width: 25vw;
	font-family: var(--MainFontType);
	background-color: rgb(100, 100, 100);
	color: var(--MainFontColor);
	border-radius: 20px;
	font-weight: 100;
	font-size: 15px;
	border: 1px solid rgb(148, 148, 148);
	text-align: center;
	margin: 5px;
	cursor: default;
}

.FeedBackContactInput::placeholder {
	color: var(--MainFontColor);
}

.FeedBackContactMessageInput {
    width: 25vw;
    height: 30vh;
	font-family: var(--MainFontType);
	background-color: rgb(100, 100, 100);
	color: var(--MainFontColor);
	border-radius: 20px;
	font-weight: 100;
	font-size: 15px;
	border: 1px solid rgb(148, 148, 148);
	text-align: center;
	margin: 5px;
	cursor: default;
}

.FeedBackContactMessageInput::placeholder {
	color: var(--MainFontColor);
}

.ContactBtns {
    background-color: var(--MainButtonColor);
    border-radius: 30px;
    width: 190px;
    padding: 0px 20px;
    height: 30px;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--MainFontColor);
    font-weight: 100;
    cursor: pointer;
    border: none;
    font-size: 17px;
}

@media screen and (max-width: 500px) {
	/*MOBILE SECTION*/

    .FeedBackContactInput {
        width: 85vw;
    }

    .FeedBackContactMessageInput {
        width: 85vw;
    }

    #ContactLogo {
        font-size: 35px;
    }
}