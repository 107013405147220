.OffersDisplayMainContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	height: auto;
	width: 100vw;
}

#OffersAndMapContainer {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: center;
	height: 485px;
	width: 100%;
	min-width: 1100px;
	margin: 0px;
	padding: 0px;
	background-color: var(--MainBackGroundColor);
}

.MobileViewSwitcher {
	display: none;
}

.Map {
    width: 100%;
    height: 550px;
    border-radius: 10px;
}

.MobileMap {
	width: 100vw;
    height: 550px;
    border-radius: 10px;
}

#NoOffersInfoHeader {
	text-align: center;
	font-size: 22px;
	font-weight: 100;
	font-family: var(--MainFontType);
	color: var(--MainFontColor);
	margin: 10px 40px;
}

.NoOffersInfoAnimation {
	color: var(--MainFontColor);
	width: 30px;
	height: 30px;
	margin-top: 20px;
	animation-name: example;
	animation-duration: 2s;
	animation-iteration-count: infinite;
}

@keyframes example {
	0% {
		transform: rotate(90deg) translateX(20px) translateY(10px);
	}
	50% {
		transform: rotate(70deg) translateX(20px) translateY(10px) rotateX(360deg);
	}
	100% {
		transform: rotate(90deg) translateX(20px) translateY(10px);
	}
  }

#NoOffersInfo {
	text-align: center;
	font-size: 16px;
	font-weight: 100;
	font-family: var(--MainFontType);
	color: var(--MainFontColor);
	margin: 10px 40px;
	border: 1px solid var(--MainFontColor);
	border-radius: 15px;
	padding: 1px 15px;
}

.OffersDisplayScrollableContainer {
	width: auto;
	min-width: 605px;
	height: 475px;
	overflow-y: scroll;
	margin-right: 6px;
}

.MapMainContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 59%;
	height: 475px;
}

.NoOffersInfoMessageContainer {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   min-height: 300px;
}

.OffersDisplayScrollableContainer::-webkit-scrollbar {
	width: 12px;
}

.OffersDisplayScrollableContainer::-webkit-scrollbar-thumb {
	background-color: rgb(122, 122, 122);
    border: 1px solid rgb(139, 139, 139);
	border-radius: 15px;
	cursor: pointer;
}


@media screen and (max-width: 500px) {
	/*MOBILE SECTION*/
	#OffersAndMapContainer {
		width: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		min-height: 350px;
		height: auto;
	}

	#OffersAndMapContainer::-webkit-scrollbar {
		width: 0px;
		visibility: hidden;
		display: none;
	}

	#NoOffersInfoHeader {
		width: 300px;
	}

	.MapMainContainer {
		height: 0px;
	}

	#OffersAndMapContainer::-webkit-scrollbar-thumb {
		display: none;
		visibility: hidden;
		width: 0px;
	}

	#NoOffersInfo {
		text-align: center;
		font-family: var(--MainFontType);
		color: var(--MainFontColor);
		font-size: 16px;
		min-height: 10px;
	}

	.OffersDisplayScrollableContainer::-webkit-scrollbar {
        width: 0px;
		display: none;
	}

	.OffersDisplayScrollableContainer::-webkit-scrollbar-thumb {
		background-color: rgb(122, 122, 122);
		border-radius: 5px;
		display: none;
		width: 0px;
	}

	.OffersDisplayScrollableContainer {
		min-width: 200px;
		width: auto;
		position:relative;
		left: 3px;
		width: auto;
		overflow-y: scroll;
		height: 100%;
	}

   .Map {
	display: none;
    width: 800px;
    height: 490px;
    border-radius: 10px;
}

.MobileViewSwitcher {
	position: fixed;
	bottom: 35px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	color: var(--MainFontColor);
	font-weight: 100;
	height: 30px;
	width: 110px;
	padding: 3px 10px;
	border-radius: 20px;
	background-color: gray;
	border: none;
	z-index: 311;
	box-shadow: 3px 4px 11px rgb(48, 48, 48);
}

.MapSwitcherText {
  font-weight: 300;
  font-size: 15px;
}

.MapButtonIcon {
	width: 20px;
	height: 20px;
    color: var(--MainFontColor);
}

}

@media screen and (min-width: 1900px) {
	/*BIG SCREEN SECTION*/

	#OffersAndMapContainer {
		width: 100%;
		height: 690px;
	}

	.OffersDisplayScrollableContainer {
		width: 635px;
		height: 680px;
	}

	.OffersDisplayScrollableContainer::-webkit-scrollbar {
		width: 15px;
	}
	
	.OffersDisplayScrollableContainer::-webkit-scrollbar-thumb {
		background-color: rgb(122, 122, 122);
		border-radius: 10px;
	}

	.MapMainContainer {
		width: 65%;
		height: 680px;
	}

	.Map {
		width: 1200px;
		height: 680px;
	}

}
