#CompanyCardMainDiv {
	width: 800px;
	height: 170px;
	font-family: var(--MainFontType);
	color: var(--MainFontColor);
	margin-bottom: 20px;
	background-color: rgb(83, 83, 83);
	border-radius: 15px;
	padding: 2px 5px;
	cursor: pointer;
	border: 1px solid rgb(100, 100, 100);
}

.CompanyNameHeaderNotDetailed {
	font-size: 18px;
	font-weight: 300;
}

.CloseDetailedCardButton {
	width: 120px;
	height: 25px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	align-self: center;
	justify-self: center;
	margin: 5px 0px;
	padding: 1px 5px;
	border-radius: 15px;
	border: none;
	font-family: var(--MainFontType);
	color: var(--MainFontColor);
	background-color: rgb(146, 146, 146);
	cursor: pointer;
	font-weight: 300;
}

.DetailedCardCloseIcon {
	width: 18px;
	height: 18px;
	margin: 0px 3px;
}

.CompanyNameHeaderDetailed {
	font-weight: 300;
	align-self: center;
	justify-self: center;
}

.CompanyCity {
	font-size: 15px;
	font-weight: 100;
	margin: 0px;
}

.CompanyWebsite {
	font-size: 15px;
	font-weight: 100;
	margin: 3px 0px;
	color: rgb(194, 183, 183);
}

.CompanyDescriptionHeader {
	font-size: 15px;
	font-weight: 100;
	border-bottom: 1px dotted rgb(85, 85, 85);
}

.CompanyOffersHeader {
	font-size: 15px;
	font-weight: 100;
}

#CompanyCardMainDivDetailed {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 800px;
	height: auto;
	font-family: var(--MainFontType);
	color: var(--MainFontColor);
	margin-bottom: 20px;
	background-color: rgb(77, 77, 77);
	border-radius: 15px;
	padding: 2px 5px;
	cursor: pointer;
	padding-bottom: 15px;
	border: 1px solid gray;
	margin: 35px 0px;
}

.ChoosedUserOffersDisplay {
	align-self: center;
}

.detailedCompanyContactDiv {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.CompanyOffersHeaderDetailed {
	font-size: 18px;
	font-weight: 300;
	align-self: center;
}

.detailedCompanyContactContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.detailedCompanyContactIcon {
	margin-right: 7px;
}

.CompanyNameAndLogo {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.CompanyLogoDetailed {
	align-self: center;
	justify-self: center;
	position: relative;
	width: 150px;
	height: 150px;
	border-radius: 10px;
}

.CompanyLogoNotDetailed {
	position: relative;
	top: 15px;
	right: 10px;
	width: 90px;
	height: 90px;
	border-radius: 10px;
}

.imgMockup {
	width: 90px;
	height: 90px;
}

@media screen and (max-width: 500px) {
	/*MOBILE SECTION*/

	#CompanyCardMainDiv {
		width: 93vw;
		height: 170px;
		font-family: var(--MainFontType);
		color: var(--MainFontColor);
		margin-bottom: 20px;
		background-color: rgb(71, 71, 71);
		border-radius: 15px;
		padding: 2px 5px;
		margin: 15px 0px;
	}

	#CompanyCardMainDivDetailed {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 97vw;
		height: auto;
		font-family: var(--MainFontType);
		color: var(--MainFontColor);
		margin-bottom: 20px;
		background-color: rgb(80, 80, 80);
		border-radius: 15px;
		padding: 2px 5px;
		cursor: pointer;
		padding-bottom: 15px;
		border: none;
	}

	.detailedCompanyContactContainer {
		font-size: 12px;
	}

	.CompanyNameHeaderNotDetailed {
		font-size: 18px;
		font-weight: 300;
	}

	.CompanyLogoNotDetailed {
		position: relative;
		top: 10px;
		right: 5px;
		width: 70px;
		height: 70px;
		border-radius: 10px;
	}

	.imgMockup {
		width: 70px;
		height: 70px;
	}
}
