#PaymentNavButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

#step3BackBtn {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 120px;
	height: 30px;
	background-color: var(--MainButtonColor);
	border-radius: 25px;
	font-size: 18px;
	border: 1px solid var(--MainFontColor);
	cursor: pointer;
	font-weight: 300;
}

#priceInfoParagraph {
	color: rgb(88, 200, 116);
}

#NavButtonsStep3 {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

#PaymentsMainDiv {
	position: relative;
	top: 30px;
	width: 100%;
	min-height: auto;
	display: flex;
	justify-content: center;
	font-weight: 100;
}

#SummaryAndPayment {
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	top: 150px;
	width: 950px;
	height: auto;
}

@media screen and (max-width: 450px) {
	/*MOBILE SECTION*/
	#PaymentNavButtons {
		flex-direction: column;
	}

	#PaymentsMainDiv {
		min-height: 650px;
	}

	#SummaryAndPayment {
		top: 120px;
		width: 100vw;
		text-align: center;
	}

}