#CompaniesDisplayMainDiv {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	min-width: 380px;
	width: 900px;
	height: 481px;
	background-color: var(--MainBackGroundColor);
	overflow-y: scroll;
}

#CompaniesDisplayMainDiv::-webkit-scrollbar {
	visibility: hidden;
	width: 18px;
}

#CompaniesDisplayMainDiv::-webkit-scrollbar-thumb {
	background-color: rgb(87, 86, 86);
	border-radius: 8px;
	border: 1px solid gray;
	width: 8px;
	height: 35px;
	cursor: pointer;
}

#NoCompanyInfo {
	font-family: var(--MainFontType);
	color: var(--MainFontColor);
	font-weight: 300;
	font-size: 20px;
}

@media screen and (max-width: 500px) {
	/*MOBILE SECTION*/

	#CompaniesDisplayMainDiv {
		position: relative;
		top: 10px;
		min-height: 70vh;
		height: auto;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
		min-width: 300px;
	}

	#CompaniesDisplayMainDiv::-webkit-scrollbar {
		visibility: hidden;
		width: 0px;
	}

	#CompaniesDisplayMainDiv::-webkit-scrollbar-thumb {
		visibility: hidden;
		width: 0px;
	}
}

@media screen and (min-width: 1900px) {
	/*BIG SCREEN SECTION*/
	#CompaniesDisplayMainDiv {
		min-height: 685px;
	}
}