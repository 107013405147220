#AddOfferInfoMainDiv {
	position: relative;
	top: 0px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	background-color: var(--MainBackGroundColor);
	width: 100%;
	height: auto;
	font-family: var(--MainFontType);
	color: var(--MainFontColor);
}

#Logo {
	font-family: var(--SecondFontType);
	font-size: 52px;
	cursor: pointer;
	text-shadow: 2px 2px 2px rgba(37, 37, 37, 0.616);
}

#SubHeader {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 10vh;
	font-weight: 100;
}

#Samples {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	width: 90%;
	height: auto;
	margin: 10px;
}

.IconsContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin: 10px;
}

.AddOfferIcons {
	width: 30px;
	height: 30px;
	color: var(--SecondFOntColor);
	margin: 0px 10px;
}

.FreeOffer {
	position: relative;
	left: 170px;
	color: orange;
}

#Samples::-webkit-scrollbar {
	background-color: var(--SecondFOntColor);
	height: 10px;
	width: 100vw;
	border-radius: 8px;
}

#Samples::-webkit-scrollbar-thumb {
	background-color: rgb(92, 92, 92);
	border-radius: 5px;
}

.SampleContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: auto;
	min-width: 520px;
	margin: 20px;
	background-color: rgb(87, 87, 87);
	border-radius: 25px;
	cursor: pointer;
	box-shadow: 2px 2px 5px rgba(61, 61, 61, 0.829);
}

#RegisterInfo {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: auto;
}

#RegisterBtn {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--MainButtonColor);
	border: none;
	border-radius: 20px;
	height: 32px;
	width: 140px;
	font-family: var(--MainFontType);
	color: var(--MainFontColor);
	font-size: 18px;
	font-weight: 300;
	cursor: pointer;
}

.AddOfferInfoHeaderContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 22px;
	font-weight: 100;
	background-color: rgb(122, 122, 122);
	width: 520px;
	height: 64px;
	margin: 0px;
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
}

#SubHeader3 {
	font-weight: 100;
}

#AddOfferInfoContactDiv {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	font-weight: 100;
}

.questions {
	font-weight: 100;
}

.AddOfferInfoText {
	font-family: var(--MainFontType);
	font-weight: 300;
	text-align: center;
}

.OfferTypeInfo {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-weight: 100;
	text-align: center;
}

#ContactDoubleDiv {
	display: flex;
	align-items: center;
	justify-content: center;
}

.Title {
	font-weight: 300;
}

#RegisterTitle {
	font-weight: 300;
}

.InfoBox {
	background-color: var(--SecondFOntColor);
	margin: 20px;
	border-radius: 30px;
	padding: 0px 15px;
}

#GratisInfo {
	color: orange;
}

#animatedScrew {
	animation-name: example;
	animation-duration: 4s;
	animation-iteration-count: infinite;
}

@keyframes example {
	from {
        transform:rotateY(0deg);
    }
    to {
        transform:rotateY(360deg);
    }
  }

  #animatedWheel {
	animation-name: wheelAnimation;
	animation-duration: 4s;
	animation-iteration-count: infinite;
  }

  @keyframes wheelAnimation {
	from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
  }


@media screen and (max-width: 500px) {
	/*MOBILE SECTION*/

	#AddOfferInfoMainDiv {
		justify-content: flex-start;
		height: auto;
	}

	#Logo {
		font-size: 30px;
		text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
	}

	#SubHeader {
		font-size: large;
		text-align: center;
	}

	#SubHeader3 {
		font-size: 13px;
	}

	#RegisterTitle {
		font-size: 25px;
	}

	.AddOfferInfoText {
		font-size: 16px;
		font-family: var(--MainFontType);
		font-weight: 300;
		text-align: center;
	}

	.InfoBox {
		background-color: var(--SecondFOntColor);
		margin: 5px;
		border-radius: 30px;
		padding: 0px 12px;
	}

	#Samples {
		width: 95%;
		height: auto;
	}

	.SampleContainer {
		min-width: 310px;
		max-width: 95vw;
		height: auto;
		margin: 15px 0px;
	}

	.AddOfferInfoHeaderContainer {
		min-width: 310px;
		max-width: 95vw;
	}

	.FreeOffer {
		position: relative;
		left: 0px;
		color: orange;
	}

	.DisplayTypeHeader {
		font-size: 25px;
	}
}

@media screen and (min-width: 1900px) {
	/*BIG SCREEN SECTION*/
}
